import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, map, mergeMap, of, tap, throwError } from 'rxjs';
import { ANYONE_ID } from '../constants/authentication.constants';
import { Environment } from '../models/environment.model';
import type { TokenInterceptorConfig } from '../models/token-interceptor.model';
import { SECURED_URLS } from '../models/token-interceptor.model';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { SyncService } from '../services/sync/sync.service';
import { forceSync, isWhitelisted, setSynced } from '../utils/sync.utils';

const JWT_RESPONSE_HEADER = 'x-auth';

@Injectable({
  providedIn: 'root',
})
export class HttpTokenInterceptor implements HttpInterceptor {
  private readonly _authenticationService = inject(AuthenticationService);
  private readonly _syncService = inject(SyncService);
  private readonly _environment = inject(Environment);
  private readonly _tokenInterceptorConfig = inject<TokenInterceptorConfig | null>(SECURED_URLS, { optional: true });

  private readonly _urlsWithBearerRegex: RegExp;

  public constructor() {
    const apiUrls = Object.keys(this._environment.apiUrls).map((envKey) => this._environment.apiUrls[envKey]);
    let urlsWithBearer: string[] = [
      ...apiUrls,
      this._environment.back_url,
      this._environment.meta_url,
      this._environment.shared_url,
      this._environment.core_api_url,
    ];
    if (this._tokenInterceptorConfig && this._tokenInterceptorConfig.urls.length > 0) {
      urlsWithBearer = [...urlsWithBearer, ...this._tokenInterceptorConfig.urls];
    }

    this._urlsWithBearerRegex = new RegExp(`^(${urlsWithBearer.join('|')})`);
  }

  /**
   * Intercept all http requests and add the bearer token if the url matches the regex.
   *
   * @param request - The http request.
   * @param next - The http handler.
   *
   * @returns The http event.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this._urlsWithBearerRegex.test(request.url) ? this._handleUrlWithBearer$(request, next) : next.handle(request);
  }

  /**
   * Handle the request with the bearer token.
   *
   * @param request - The http request.
   * @param next - The http handler.
   *
   * @returns The http event with the bearer token.
   */
  private _handleUrlWithBearer$(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = {
      Accept: 'application/json',
    };

    if (!isWhitelisted(this._environment, request)) {
      const isSynced = this._syncService.isSynced();

      if (!isSynced) {
        return this._syncService.sync(request).pipe(
          map(() => this._addBearer(request, headers)),
          mergeMap((newRequest) => this._handleBearerResponse$(newRequest, next)),
        );
      }
    }

    return of(true).pipe(
      map(() => this._addBearer(request, headers)),
      mergeMap((newRequest) => this._handleBearerResponse$(newRequest, next)),
    );
  }

  /**
   * Handle the bearer response and store the token if it is present.
   *
   * @param request - The http request.
   * @param next - The http handler.
   *
   * @returns The http event with the bearer response.
   */
  private _handleBearerResponse$(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (!(event instanceof HttpResponse)) {
          return;
        }
        const response = event as HttpResponse<unknown>;
        if (!!response.headers.keys() && !!response.headers.get(JWT_RESPONSE_HEADER)) {
          this._storeBearer(response.headers.get(JWT_RESPONSE_HEADER) as string);
        }
      }),
      catchError((error) => {
        if (error.status === 401) {
          forceSync();
        }
        return throwError(error);
      }),
    );
  }

  private _addBearer(request: HttpRequest<unknown>, headers: { [name: string]: unknown }) {
    this._authenticationService.checkAuthToken();

    let authHeader = {};

    const userData = this._authenticationService.getCurrentUserValue();

    if ((userData && userData.id !== ANYONE_ID) || !!this._authenticationService.deviceId) {
      authHeader = {
        Authorization: `Bearer ${this._authenticationService.authToken}`,
      };
    }

    return request.clone({
      setHeaders: {
        ...headers,
        ...authHeader,
      },
    });
  }

  private _storeBearer(auth: string) {
    if (!!auth && this._authenticationService.authToken !== auth) {
      this._authenticationService.authToken = auth;
      // set service as synced
      setSynced();
    }
  }
}

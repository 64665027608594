<p-overlayPanel class="user-menu" #menu>
  <ng-template pTemplate="content">
    <div class="min-w-240 flex flex-col items-start">
      <div class="flex gap-8 items-center">
        <img class="size-24" src="assets/images/logos/logo.svg" alt="DEXTools logo" />
        <span>{{ account() | addressEllipsis }}</span>
      </div>
      <p-divider class="w-full"></p-divider>

      <div class="flex flex-col gap-8">
        <p-button class="user" icon="pi pi-power-off" [label]="'disconnect' | translate" (click)="disconnect(); menu.hide()"></p-button>
        <a class="link py-10 pe-18 flex gap-8" href="https://dextools.io/app" target="_blank" rel="noopener">
          <img class="size-14" src="assets/images/logos/logo.svg" alt="DEXTools logo" />
          <span class="font-14">{{ 'go_to_dextools' | translate }}</span>
        </a>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

@if (!!account()) {
  <p-button class="user" (click)="menu.toggle($event)">
    @if (isVerified()) {
      <fa-icon class="color-text-secondary" [icon]="icons.faUser" size="xl"></fa-icon>
      <p-tag [value]="role()" [ngClass]="role()"></p-tag>
    } @else {
      <i class="pi pi-spin pi-spinner"></i>
      <p-tag class="free" [value]="'verifying' | translate"></p-tag>
    }
  </p-button>
} @else {
  <app-connect-wallet></app-connect-wallet>
}

import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import type { TokenSocials } from '@dextools/blockchains';
import { PairsUtil } from '@dextools/blockchains';
import { AddressEllipsisPipe, DeviceService, RoundBigNumberPipe } from '@dextools/ui-utils';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBitbucket,
  faDiscord,
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faMedium,
  faReddit,
  faTelegram,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faCopy, faGlobe, faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillTransfer } from '@fortawesome/pro-solid-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { take } from 'rxjs/operators';

import { VerifiedLogoComponent } from '../shared/verified-logo/verified-logo.component';
import { ClipboardService } from '../../services/clipboard.service';
import { StoreService } from '../../services/store.service';
import { TokenCreatorService } from '../../services/token-creator.service';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { CreatedToken } from '../../models/my-tokens.model';
import { TokenService } from '@dextools/blockchains/services';

@Component({
  selector: 'app-my-tokens',
  standalone: true,
  templateUrl: './my-tokens.component.html',
  styleUrls: ['./my-tokens.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AddressEllipsisPipe,
    ButtonModule,
    CardModule,
    ChipModule,
    DropdownModule,
    FontAwesomeModule,
    FormsModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    RoundBigNumberPipe,
    SkeletonModule,
    TableModule,
    TippyDirective,
    TranslateModule,
    VerifiedLogoComponent,
  ],
})
export class MyTokensComponent {
  @Output()
  public readonly addLiquidity = new EventEmitter<CreatedToken | undefined>();

  @Output()
  public readonly blacklist = new EventEmitter<CreatedToken | undefined>();

  @Output()
  public readonly createToken = new EventEmitter<void>();

  protected readonly blockchainsConfig = this._tokenCreatorService.blockchainsConfig;
  protected readonly blockchainOptions = Object.values(this.blockchainsConfig);
  protected readonly deviceName = this._deviceService.getDeviceConfig('displayResolution');
  protected readonly icons = {
    faCopy,
    faMoneyBillTransfer,
    faShareAlt,
  };
  protected readonly socialIcons: Partial<Record<keyof TokenSocials, IconDefinition>> = {
    website: faGlobe,
    telegram: faTelegram,
    twitter: faXTwitter,
    instagram: faInstagram,
    facebook: faFacebook,
    bitbucket: faBitbucket,
    discord: faDiscord,
    youtube: faYoutube,
    linkedin: faLinkedin,
    github: faGithub,
    reddit: faReddit,
    medium: faMedium,
    tiktok: faTiktok,
  };
  protected readonly tokens = this._storeService.myTokens;

  protected readonly account = toSignal(this._tokenCreatorService.account$);
  protected readonly blockchain = toSignal(this._tokenCreatorService.blockchain$);
  protected readonly isLoading = signal(false);
  protected readonly isSwitchingChain = signal(false);
  protected readonly selectedBlockchain = signal<number | null>(null);

  public constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _clipboardService: ClipboardService,
    private readonly _destroyRef: DestroyRef = inject(DestroyRef),
    private readonly _deviceService: DeviceService,
    private readonly _storeService: StoreService,
    private readonly _tokenCreatorService: TokenCreatorService,
    private readonly _tokenService: TokenService,
  ) {
    this._tokenCreatorService.account$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async () => {
      await this._getCreatedTokens();
    });

    this._tokenCreatorService.blockchain$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async (value) => {
      await this._getCreatedTokens();
      if (value) this.selectedBlockchain.set(value.id);
    });
  }

  protected copyToClipboard(event: Event, text: string, tippy: TippyDirective) {
    event.stopPropagation();
    this._clipboardService.copyToClipboard(text, tippy);
  }

  protected hasSocials(token: CreatedToken) {
    return Object.values(token.socials).some((s) => s !== '');
  }

  protected async selectBlockchain(value: number) {
    const selectedBlockchain = this.selectedBlockchain();

    if (selectedBlockchain === null) {
      this.selectedBlockchain.set(value);
      return;
    }

    this.isSwitchingChain.set(true);
    try {
      await this._tokenCreatorService.selectNetwork(value);
    } catch (error) {
      // Hack to revert selected blockchain to the previous one if the user rejected switching networks in wallet provider
      this.selectedBlockchain.set(null);
      setTimeout(() => {
        this.selectedBlockchain.set(selectedBlockchain);
      }, 0);
      console.error(error);
    }
    this.isSwitchingChain.set(false);
  }

  protected getSocialKeys(token: CreatedToken) {
    return (Object.keys(token.socials) as (keyof TokenSocials)[]).filter((key) => token.socials[key] !== '').slice(0, 4);
  }

  protected onAddLiquidity(token?: CreatedToken) {
    this.addLiquidity.next(token);
  }

  protected onBlacklist(token?: CreatedToken) {
    this.blacklist.next(token);
  }

  protected onCreateToken() {
    this.createToken.next();
  }

  private async _getCreatedTokens() {
    this.isLoading.set(true);

    try {
      const tokens = await this._tokenCreatorService.getCreatedTokens();
      this._storeService.setMyTokens(tokens);

      await this._getSocialsAndLogos();
    } catch (error) {
      console.error(error);
      this._storeService.setMyTokens([]);
    }

    this.isLoading.set(false);
  }

  private async _getSocialsAndLogos() {
    const tokens = this._storeService.myTokens();
    if (tokens.length === 0) return;

    const tokensWithChain = tokens.map((t) => `${this.blockchainsConfig[t.blockchainId].slug}:${t.contract.toLowerCase()}`);
    this._tokenService
      .getSocialsAndLogos(tokensWithChain)
      .pipe(takeUntilDestroyed(this._destroyRef), take(1))
      .subscribe((response) => {
        if (!response.data) return;

        for (const item of response.data) {
          const token = tokens.find((t) => t.contract.toLowerCase() === item.id.token);
          if (!token) continue;

          if (item.links) token.socials = item.links;
          if (item.logo) token.logo = PairsUtil.normalizeLogoUrl(item.logo);
        }

        this._cdRef.detectChanges();
      });
  }
}

<app-contract-mode></app-contract-mode>
<app-steps class="w-full"></app-steps>

@if (mode() === Mode.BASIC) {
  <app-basic-form class="w-full flex justify-center"></app-basic-form>
} @else {
  <app-advanced-form class="w-full flex justify-center"></app-advanced-form>
}

@if (blockchain()?.unsupported) {
  <p-messages class="px-28" severity="error">
    <ng-template pTemplate>
      <span class="flex justify-center items-center font-20">
        {{ 'forms.actions.chain_unsupported' | translate }}
      </span>
    </ng-template>
  </p-messages>
} @else {
  <p-button
    class="next"
    icon="pi pi-arrow-right"
    size="large"
    [label]="'create.actions.next' | translate"
    [rounded]="true"
    [outlined]="true"
    (click)="submit()"
  ></p-button>
}

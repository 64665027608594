import { Injectable } from '@angular/core';
import { AuthenticationService, CryptoUtil, LOCALSTORAGE, SyncService } from '@dextools/core';
import { LocalStorageUtil } from '@dextools/utils';
import type { Observable } from 'rxjs';
import { switchMap } from 'rxjs';
import { ConfigPagesService } from '@dextools/blockchains/services';
import { AnalyticsService } from '@dextools/analytics';

const OLD_TOKEN = 'dataAnyone';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _configPagesService: ConfigPagesService,
    private readonly _syncService: SyncService,
    private readonly _analyticsService: AnalyticsService,
  ) {}

  public init(): Observable<unknown> {
    const featuresEnv = process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod' ? 'features_app' : 'features_stage';
    // execute these tasks "in parallel" for faster startup (just do some stuff in the meantime some Http requests return data)
    // Authentication-related tasks

    return CryptoUtil.loadCryptoLibs().pipe(
      switchMap(() => {
        // IMPORTANT: crypto libs are used by Authentication Service!
        this._authenticationService.initialize();

        this._syncService.initialize();
        this._prepareLogin();
        if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] !== 'local') {
          // IMPORTANT: the analytics script should be loaded before calling any code of the Analytics provider (Matomo)

          this._analyticsService.loadScript().then(() => {
            this._analyticsService.initialize();
          });
        }
        return this._configPagesService.fetchConfigFromPage$(featuresEnv);
      }),
    );
  }

  private _prepareLogin() {
    if (this._authenticationService.authToken != null) {
      return;
    }
    let token: string | null;

    // get logged userData from storage
    const data = JSON.parse(LocalStorageUtil.getString(LOCALSTORAGE.USER_DATA) as string);

    if (data) {
      // get logged token
      token = data.jwt;
      if (token) {
        // remove jwt token from userData
        delete data.jwt;
        LocalStorageUtil.setString(LOCALSTORAGE.USER_DATA, JSON.stringify(data));
      } else {
        // remove user data (corrupt data)
        this._authenticationService.logout();
      }
    } else {
      token = LocalStorageUtil.getString(OLD_TOKEN);
    }

    if (token) {
      // place new token
      this._authenticationService.authToken = token;
    }

    // remove old token
    LocalStorageUtil.delete(OLD_TOKEN);
  }
}

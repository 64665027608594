import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'primeng/panel';

import { FaqComponent } from '../faq/faq.component';
import { TokenInfoComponent } from '../shared/token-info/token-info.component';

@Component({
  selector: 'app-basic-form',
  standalone: true,
  imports: [FaqComponent, PanelModule, TranslateModule, TokenInfoComponent],
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicFormComponent {}

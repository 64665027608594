import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageUtil, SettingsService } from '@dextools/core';

import type { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import type { TokenCreator } from '../../models/config.model';

export const languageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const settingsService = inject<SettingsService<TokenCreator>>(SettingsService);

  const language = route.params['language'];

  if (LanguageUtil.languageList.includes(language)) {
    if (language !== settingsService.language) {
      const url = state.url.replace(`/${language}`, `/${settingsService.language}`);
      router.navigate([url]);
      return false;
    }
  } else {
    // then the `param` is a not a recognized language, so triggering a navigation through our service should add the missing language path ;)
    router.navigate(['/']);
    return false;
  }

  return true;
};

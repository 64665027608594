import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInstagram, faMedium, faTelegram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { filter } from 'rxjs';

import { GeneralStatementModalComponent } from './general-statement-modal/general-statement-modal.component';
import { LegalAdviceModalComponent } from './legal-advice-modal/legal-advice-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogModule, FontAwesomeModule, GeneralStatementModalComponent, LegalAdviceModalComponent, TippyDirective, TranslateModule],
})
export class FooterComponent {
  protected readonly icons = {
    faInstagram,
    faMedium,
    faTelegram,
    faTiktok,
    faXTwitter,
    faYoutube,
  };

  protected readonly currentYear = new Date().getFullYear();

  protected readonly isGeneralStatementVisible = signal(false);
  protected readonly isLegalAdviceVisible = signal(false);

  private readonly _destroyRef = inject(DestroyRef);

  public constructor(private readonly _actRoute: ActivatedRoute) {
    this._actRoute.fragment
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((fragment) => !!fragment),
      )
      .subscribe((fragment) => {
        if (fragment === 'general-statement') {
          this.showGeneralStatement(true);
        } else if (fragment === 'privacy-policy') {
          this.showLegalAdvice(true);
        }
      });
  }

  protected showLegalAdvice(value: boolean) {
    this.isLegalAdviceVisible.set(value);
  }

  protected showGeneralStatement(value: boolean) {
    this.isGeneralStatementVisible.set(value);
  }
}

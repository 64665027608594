import type { Routes } from '@angular/router';
import { languageResolver, LanguageUtil } from '@dextools/core';

import { CreatePageComponent } from './pages/create/create-page.component';
import { LandingPageComponent } from './pages/landing/landing-page.component';
import { MyTokensPageComponent } from './pages/my-tokens/my-tokens-page.component';
import { languageGuard } from './router/guards/language.guard';

const language = LanguageUtil.getCurrentStoredLanguage('configApp');

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${language}`,
  },
  {
    path: 'create',
    pathMatch: 'full',
    redirectTo: `${language}/create`,
  },
  {
    path: 'my-tokens',
    pathMatch: 'full',
    redirectTo: `${language}/my-tokens`,
  },
  {
    path: ':language',
    component: LandingPageComponent,
    canActivate: [languageGuard],
    resolve: {
      currentLanguage: languageResolver,
    },
  },
  {
    path: ':language/create',
    component: CreatePageComponent,
    canActivate: [languageGuard],
    resolve: {
      currentLanguage: languageResolver,
    },
  },
  {
    path: ':language/my-tokens',
    component: MyTokensPageComponent,
    canActivate: [languageGuard],
    resolve: {
      currentLanguage: languageResolver,
    },
  },
  // default route (should come last, it is selected when no other routes matched)
  {
    path: '**',
    redirectTo: `${language}`,
  },
];

import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import type { AppConfig, Language } from '../models/config.model';
import { SettingsService } from '../services/settings/settings.service';
import { LanguageUtil } from '../utils/language.util';

export const languageResolver: ResolveFn<Language> = (route: ActivatedRouteSnapshot) => {
  const settingsService = inject<SettingsService<AppConfig>>(SettingsService);

  const languageInSettings = settingsService.language;
  const languageInUrl = (route.params['language'] as Language) ?? null;
  const isLanguageUrl = languageInUrl ? LanguageUtil.languageList.includes(languageInUrl) : false;

  if (isLanguageUrl && languageInSettings === languageInUrl) {
    // IMPORTANT: languageInSettings is the default browser's language in case it's the first time the user opens the app
    return languageInSettings;
  }
  return settingsService.language;
};

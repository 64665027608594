<p-accordion class="faq" [activeIndex]="0">
  <p-accordionTab [header]="'faq.benefits.header' | translate">
    <p class="font-18 leading-normal" [innerHTML]="'faq.benefits.content' | translate"></p>
    <br />
    <p class="font-18 leading-normal" [innerHTML]="'faq.benefits.content2' | translate"></p>
  </p-accordionTab>
  <p-accordionTab [header]="'faq.fees.header' | translate">
    <p class="font-18 leading-normal" [innerHTML]="'faq.fees.content' | translate"></p>
    <table class="w-full my-28 font-18">
      <thead>
        <tr class="row py-21 flex">
          <td class="flex-1 font-21 font-bold uppercase">BLOCKCHAIN</td>
          <td class="flex-1 font-21 font-bold uppercase">{{ 'faq.fees.fee' | translate }}</td>
        </tr>
      </thead>
      <tbody>
        @for (chain of chains; track 'table' + chain.name) {
          <tr class="row py-7 flex">
            <td class="flex-1">
              <div class="flex items-center gap-8">
                <img class="size-20" loading="lazy" [src]="chain.logo" [alt]="chain.name + ' logo'" />
                <span>{{ chain.name }}</span>
              </div>
            </td>
            <td class="flex-1">{{ chain.fee }}</td>
          </tr>
        }
      </tbody>
    </table>
    <p class="font-18 leading-normal">{{ 'faq.fees.content2' | translate }}</p>
  </p-accordionTab>
  <p-accordionTab [header]="'faq.chains.header' | translate">
    <p class="font-18 leading-normal">
      <span>{{ 'faq.chains.content' | translate }}</span>
      @for (chain of chains; track 'chains' + chain.name) {
        <span class="ps-4 inline-flex items-baseline gap-8">
          <img class="size-18" loading="lazy" [src]="chain.logo" [alt]="chain.name + ' logo'" />
          <span>{{ chain.name }}</span>
        </span>
        @if ($index < chains.length - 1) {
          ,
        }
      }
      <span>{{ 'faq.chains.content2' | translate }}</span>
    </p>
  </p-accordionTab>
  <p-accordionTab [header]="'faq.next_steps.header' | translate">
    <p class="font-18 leading-normal">{{ 'faq.next_steps.content' | translate }}</p>
    <br />
    <p class="font-18 leading-normal" [innerHTML]="'faq.next_steps.content2' | translate"></p>
  </p-accordionTab>
  <p-accordionTab [header]="'faq.help.header' | translate">
    <p class="font-18 leading-normal" [innerHTML]="'faq.help.content' | translate"></p>
  </p-accordionTab>
</p-accordion>

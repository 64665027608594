<p-panel>
  <ng-template pTemplate="header">
    <div class="flex items-center justify-start gap-14">
      <fa-icon class="opacity-60" [icon]="icons.faSliders" size="xl"></fa-icon>
      <span class="font-21 font-semibold uppercase">3. {{ 'forms.advanced_options.header.title' | translate }}</span>
    </div>
  </ng-template>

  @switch (type()) {
    @case (Type.STANDARD) {
      @defer (when type() === Type.STANDARD) {
        <app-standard-token-options></app-standard-token-options>
      }
    }

    @case (Type.BURN) {
      @defer (when type() === Type.BURN) {
        <app-burn-token-options></app-burn-token-options>
      }
    }

    @case (Type.BABY) {
      @defer (when type() === Type.BABY) {
        <app-baby-token-options></app-baby-token-options>
      }
    }

    @case (Type.LIQUIDITY_GENERATOR) {
      @defer (when type() === Type.LIQUIDITY_GENERATOR) {
        <app-liquidity-generator-token-options></app-liquidity-generator-token-options>
      }
    }

    @case (Type.LIQUIDITY_FEE) {
      @defer (when type() === Type.LIQUIDITY_FEE) {
        <app-liquidity-fee-token-options></app-liquidity-fee-token-options>
      }
    }

    @case (Type.LIQUIDITY_BUY_SELL_FEE) {
      @defer (when type() === Type.LIQUIDITY_BUY_SELL_FEE) {
        <app-liquidity-buy-sell-fee-token-options></app-liquidity-buy-sell-fee-token-options>
      }
    }
  }

  <div class="flex flex-col gap-20">
    <p-divider>{{ 'forms.headers.blacklist' | translate }}</p-divider>

    @if (deviceName() !== 'mobile') {
      <div [formGroup]="form" class="flex items-center gap-20">
        <div class="flex items-center gap-14">
          <fa-icon [icon]="icons.faRobot" size="xl"></fa-icon>
          <label class="font-21" style="width: 200px" for="antibot">{{ 'forms.fields.antibot.label' | translate }}</label>
        </div>
        <div class="flex flex-col gap-8" style="max-width: 400px">
          <p-inputSwitch formControlName="hasAntibot" id="antibot" />
          <span class="font-14 color-text-secondary">{{ 'forms.fields.antibot.description' | translate }}</span>
        </div>
      </div>
    } @else {
      <div [formGroup]="form" class="flex flex-col items-start justify-center gap-10">
        <div class="flex items-center gap-14">
          <fa-icon [icon]="icons.faRobot" size="xl"></fa-icon>
          <label class="font-21" for="antibot">{{ 'forms.fields.antibot.label' | translate }}</label>
          <p-inputSwitch formControlName="hasAntibot" id="antibot" />
        </div>
        <span class="font-14 color-text-secondary">{{ 'forms.fields.antibot.description' | translate }}</span>
      </div>
    }
  </div>
</p-panel>

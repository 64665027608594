import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'primeng/panel';

import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';
import { ContractTypeComponent } from '../contract-type/contract-type.component';
import { TokenInfoComponent } from '../shared/token-info/token-info.component';

@Component({
  selector: 'app-advanced-form',
  standalone: true,
  imports: [AdvancedOptionsComponent, ContractTypeComponent, PanelModule, TokenInfoComponent, TranslateModule],
  templateUrl: './advanced-form.component.html',
  styleUrls: ['./advanced-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedFormComponent {
  public constructor() {}
}

<p-button
  class="uppercase"
  icon="pi pi-power-off"
  [attr.aria-label]="'connect' | translate"
  [label]="'connect' | translate"
  [outlined]="true"
  [rounded]="true"
  (click)="connect()"
>
</p-button>

import { InjectionToken } from '@angular/core';
import type { ProcessLinkData } from './links.model';

export interface AnalyticsConfig {
  podContainer: string;
  app: string;
  urlMappingFn?: (url: string) => string | null;
  /**
   * Array containing the processes to be tracked. Each item is a process containing the different steps involved.
   */
  processLinks: ProcessLinkData[];
  /**
   * Function that should return the value (string) to be reported as `hierarchy3` in the MatomoObject.
   * The return value can be an empty string ('') in case there is no value to be reported.
   *
   * @param url - the current url
   */
  hierarchy3GetterFn: (url: string) => string;
}

export const ANALYTICS_CONFIG = new InjectionToken<AnalyticsConfig>('AnalyticsConfig');

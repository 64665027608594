<footer class="footer px-21 py-14 items-center justify-between flex">
  <div class="socials flex gap-14 items-center">
    <a class="color-text-secondary opacity-60" target="_blank" rel="noopener" href="https://twitter.com/DEXToolsApp">
      <fa-icon [icon]="icons.faXTwitter" size="lg"></fa-icon>
    </a>
    <a class="color-text-secondary opacity-60" target="_blank" rel="noopener" href="https://t.me/DEXToolsCommunity">
      <fa-icon [icon]="icons.faTelegram" size="lg"></fa-icon>
    </a>
    <a class="color-text-secondary opacity-60" target="_blank" rel="noopener" href="https://dextools.medium.com">
      <fa-icon [icon]="icons.faMedium" size="lg"></fa-icon>
    </a>
    <a class="color-text-secondary opacity-60" target="_blank" rel="noopener" href="https://www.instagram.com/dextools.official/">
      <fa-icon [icon]="icons.faInstagram" size="lg"></fa-icon>
    </a>
    <a class="color-text-secondary opacity-60" target="_blank" rel="noopener" href="https://www.tiktok.com/@dextools">
      <fa-icon [icon]="icons.faTiktok" size="lg"></fa-icon>
    </a>
    <a
      class="color-text-secondary opacity-60"
      target="_blank"
      rel="noopener"
      href="https://www.youtube.com/c/DEXToolsAcademy?sub_confirmation=1"
    >
      <fa-icon [icon]="icons.faYoutube" size="lg"></fa-icon>
    </a>
  </div>
  <div class="flex-container">
    <div class="flex items-center justify-center gap-22">
      <a
        href="https://apps.apple.com/us/app/dextools/id1628834724"
        [tp]="qrIos"
        [tpAnimation]="false"
        [tpDelay]="0"
        rel="noopener"
        target="_blank"
      >
        <img loading="lazy" src="assets/images/footer/appstore.svg" alt="app-store app link" height="56px" width="127px" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=io.dextools.app"
        [tp]="qrAndroid"
        [tpAnimation]="false"
        [tpDelay]="0"
        rel="noopener"
        target="_blank"
      >
        <img loading="lazy" src="assets/images/footer/googleplay.svg" alt="g-play app link" height="56px" width="127px" />
      </a>
    </div>
    <div class="right-container flex flex-col justify-center gap-14">
      <div class="flex items-center justify-center gap-14 wrap">
        <a href="#" (click)="showGeneralStatement(true); (false)">{{ 'footer.general_statement' | translate }}</a>
        <a href="#" (click)="showLegalAdvice(true); (false)">{{ 'footer.legal_advice' | translate }}</a>
        <a target="_blank" rel="noopener" href="https://info.dextools.io/">{{ 'footer.about_us' | translate }}</a>
        <a target="_blank" rel="noopener" href="https://info.dextools.io/#dextoken">{{ 'footer.token' | translate }}</a>
        <a target="_blank" rel="noopener" href="https://info.dextools.io/#about-us">{{ 'footer.team' | translate }}</a>
        <a target="_blank" rel="noopener" href="https://info.dextools.io/#contact">{{ 'footer.contact' | translate }}</a>
      </div>
      <p class="marketing-container flex items-center gap-8">
        <span>
          © DEXTools.io {{ currentYear }} -
          <a href="mailto:info@dextools.io">info&#64;dextools.io</a>
        </span>
        <span class="desktop-only">|</span>
        <span>
          Ads & Marketing:
          <a href="mailto:marketing@dextools.io">marketing&#64;dextools.io</a>
        </span>
      </p>
    </div>
  </div>
</footer>

<p-dialog
  [visible]="isLegalAdviceVisible()"
  [modal]="true"
  [dismissableMask]="false"
  [resizable]="false"
  [draggable]="false"
  [style]="{ width: '500px' }"
  (visibleChange)="showLegalAdvice($event)"
>
  <ng-template pTemplate="header">
    <div class="w-full flex flex-col items-center justify-center gap-8">
      <img src="assets/images/logos/logo.svg" alt="DEXTools Token Creator logo" width="35" />
      <h5>{{ 'footer.legal_advice' | translate }}</h5>
    </div>
  </ng-template>
  @defer (when isLegalAdviceVisible()) {
    <app-legal-advice-modal></app-legal-advice-modal>
  }
</p-dialog>

<p-dialog
  [visible]="isGeneralStatementVisible()"
  [modal]="true"
  [dismissableMask]="false"
  [resizable]="false"
  [draggable]="false"
  [style]="{ width: '500px' }"
  (visibleChange)="showGeneralStatement($event)"
>
  <ng-template pTemplate="header">
    <div class="w-full flex flex-col items-center justify-center gap-8">
      <img src="assets/images/logos/logo.svg" alt="DEXTools Token Creator logo" width="35" />
      <h5>{{ 'footer.general_statement' | translate }}</h5>
    </div>
  </ng-template>
  @defer (when isGeneralStatementVisible()) {
    <app-general-statement-modal></app-general-statement-modal>
  }
</p-dialog>

<ng-template #qrIos>
  <div class="qr-code-popover">
    <div class="qr-code-container">
      <img src="assets/images/footer/qr-ios.png" alt="QR code iOS mobile app" width="240px" height="240px" />
    </div>
  </div>
</ng-template>

<ng-template #qrAndroid>
  <div class="qr-code-popover">
    <div class="qr-code-container">
      <img src="assets/images/footer/qr-android.png" alt="QR code Android mobile app" width="240px" height="240px" />
    </div>
  </div>
</ng-template>

import type { HttpRequest } from '@angular/common/http';
import { LocalStorageUtil } from '@dextools/utils';
import type { Environment } from '../models/environment.model';

const LOCAL_STORAGE_LAST_SYNCED = 'lastSynced';

export const AUTO_SYNC_FLAG = '<autosync>';

/**
 * Utils used to sync login when there's been inactivity for a period of time
 */

/**
 * Forces a sync call by removing last sync date reference
 * Should only be called when the app is not authenticated correctly
 */
export function forceSync(): void {
  LocalStorageUtil.delete(LOCAL_STORAGE_LAST_SYNCED);
}

/**
 * Activate sync flag from an external service
 */
export function setSynced(): void {
  LocalStorageUtil.setDate(LOCAL_STORAGE_LAST_SYNCED, new Date());
}

/**
 * Checks if a request is on the urls whitelist
 *
 * @param environment - Current environment
 * @param request - Request where to check url from
 *
 * @returns `true` in case the request is whitelisted, `false` otherwise
 */
export function isWhitelisted(environment: Environment, request: HttpRequest<unknown>): boolean {
  if (request == null) {
    return false;
  }

  const WHITELISTED_URLS = [`${environment.back_url}/user/login`, AUTO_SYNC_FLAG];
  return WHITELISTED_URLS.some((url) => request.url.startsWith(url));
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { TokenCreatorService } from '../../../services/token-creator.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, TranslateModule],
  selector: 'app-connect-wallet',
  templateUrl: './connect-wallet.component.html',
  styleUrls: ['./connect-wallet.component.scss'],
})
export class ConnectWalletComponent {
  protected readonly icons = {
    faUser,
  };
  public constructor(private readonly _tokenCreatorService: TokenCreatorService) {}

  public async connect() {
    await this._tokenCreatorService.connect();
  }
}

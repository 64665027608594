/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

import { appTitle, appVersion, externalLink } from './shared';

import type { Environment } from '@dextools/core';

const domain = 'www.dextools.io';
const appScope = '';
const host = `https://${domain}`;
const appUrl = `${host}${appScope}`;
const coreApiURL = `https://core-api.dextools.io`;

class EnvironmentImpl implements Environment {
  public appTitle = appTitle;
  public externalLink = externalLink;
  public allowAdmin = false; // no admin in this app
  public app_scope = appScope;
  public appUrl = `${appUrl}`;
  public host = `${host}`;
  public assets_url = `${appUrl}/assets`;
  public appLogo = `${appUrl}/assets/images/logos/logo.svg`;
  public apiUrls = {};
  public back_url = `${host}:3530/back`;
  public meta_url = `${host}:3530/meta`;
  public ws_url = `ws://${domain}:8501`;
  public paths = {};
  public production = true;
  public whitelistedUrls = [] as string[];
  public siteId = '';
  public appVersion = appVersion;
  public shared_url = `${host}/shared`;
  public cf_proxy_url = `${host}/cz`;
  public core_api_url = coreApiURL;
}

export const environment = new EnvironmentImpl();

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthenticationService, Role } from '@dextools/core';
import { AddressEllipsisPipe } from '@dextools/ui-utils';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TagModule } from 'primeng/tag';

import { ConnectWalletComponent } from '../../../components/shared/connect-wallet/connect-wallet.component';
import { TokenCreatorService } from '../../../services/token-creator.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AddressEllipsisPipe,
    ButtonModule,
    ConnectWalletComponent,
    DividerModule,
    FontAwesomeModule,
    NgClass,
    OverlayPanelModule,
    TagModule,
    TranslateModule,
  ],
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent {
  protected readonly icons = {
    faUser,
  };

  protected readonly account = toSignal(this._tokenCreatorService.account$);
  protected readonly isVerified = signal(false);
  protected readonly role = signal<'free' | 'standard' | 'premium'>('free');

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _destroyRef: DestroyRef = inject(DestroyRef),
    private readonly _tokenCreatorService: TokenCreatorService,
  ) {
    this._authenticationService.currentUser$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((user) => {
      if (user) {
        this._getRole(user.plan);
      }
    });

    this._authenticationService.isVerified$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      this.isVerified.set(value);
    });
  }

  protected async disconnect() {
    await this._tokenCreatorService.disconnect();
    this._authenticationService.logout();
  }

  private _getRole(role: Role) {
    switch (role) {
      case Role.P100: {
        this.role.set('premium');

        break;
      }
      case Role.P20: {
        this.role.set('standard');

        break;
      }
      case Role.P0: {
        this.role.set('free');

        break;
      }
    }
  }
}

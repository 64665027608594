<app-my-tokens
  class="max-w-1512 mx-auto p-28"
  (addLiquidity)="onAddLiquidity($event)"
  (blacklist)="onBlacklist($event)"
  (createToken)="onCreateToken()"
></app-my-tokens>

@switch (activePanel()) {
  @case ('blacklist') {
    @defer (when activePanel() === 'blacklist') {
      <app-blacklist [token]="token()"></app-blacklist>
    }
  }
  @case ('liquidity') {
    @defer (when activePanel() === 'liquidity') {
      <app-liquidity [token]="token()"></app-liquidity>
    }
  }
}

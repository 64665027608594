import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Chains } from '@dextools/blockchains';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [AccordionModule, TranslateModule],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  protected readonly chains = [
    { name: Chains.Ethereum.chainName, fee: '0.3 ETH', logo: Chains.Ethereum.logoUrl },
    { name: Chains.Binance.chainName, fee: '1.5 BNB', logo: Chains.Binance.logoUrl },
    { name: Chains.Base.chainName, fee: '0.3 ETH', logo: Chains.Base.logoUrl },
    { name: Chains.Arbitrum.chainName, fee: '0.3 ETH', logo: Chains.Arbitrum.logoUrl },
    { name: Chains.Avalanche.chainName, fee: '30 AVAX', logo: Chains.Avalanche.logoUrl },
    { name: Chains.Polygon.chainName, fee: '1 500 MATIC', logo: Chains.Polygon.logoUrl },
    { name: Chains.Optimism.chainName, fee: '0.3 ETH', logo: Chains.Optimism.logoUrl },
    { name: Chains.Pulse.chainName, fee: '17 500 000 PLS', logo: Chains.Pulse.logoUrl },
  ];

  protected readonly chainNames = Object.values(this.chains)
    .map((c) => c.name)
    .join(', ');
}

<p-panel class="w-full h-full" [ngClass]="{ 'opacity-60': !isConnected() }">
  <ng-template pTemplate="header">
    <div class="flex items-center justify-start gap-14">
      <fa-icon class="opacity-60" [icon]="icons.faPenToSquare" size="xl"></fa-icon>
      <span class="font-21 font-semibold uppercase">{{ 'forms.token_info.header.title' | translate }}</span>
    </div>
  </ng-template>

  <div [formGroup]="form" class="w-full flex flex-col items-center justify-center gap-28">
    <!-- NAME -->
    <div
      class="w-full flex"
      [class]="deviceName() === 'desktop' ? 'items-center flex-grow gap-20' : 'flex-col items-start justify-centerflex-grow gap-10'"
    >
      <label class="flex flex-shrink flex-basis-33 font-21" for="name">
        {{ 'forms.fields.name.label' | translate }}
        <span [class]="{ 'color-text-error': form.touched && name.errors && name.errors['required'] }">*</span>
      </label>
      <div class="flex flex-col flex-grow flex-basis-64 gap-4" [class]="{ 'w-full': deviceName() !== 'desktop' }">
        <input
          pInputText
          autocomplete="off"
          class="font-21"
          formControlName="name"
          id="name"
          required
          minlength="1"
          maxlength="32"
          type="text"
          [placeholder]="'forms.fields.name.placeholder' | translate"
        />
        <span class="ps-4 font-14 color-text-secondary">
          <span class="opacity-60" [ngClass]="{ 'color-text-error opacity-100': form.touched && name.errors && name.errors['required'] }">
            {{ 'forms.common.required' | translate }}
          </span>
          <span class="opacity-60" [ngClass]="{ 'color-text-error opacity-100': form.touched && name.errors && name.errors['pattern'] }">
            {{ 'forms.fields.name.description' | translate }}
          </span>
        </span>
      </div>
    </div>

    <!-- SYMBOL -->
    <div
      class="w-full flex"
      [class]="deviceName() === 'desktop' ? 'items-center flex-grow gap-20' : 'flex-col items-start justify-centerflex-grow gap-10'"
    >
      <label class="flex flex-shrink flex-basis-33 font-21" for="symbol">
        {{ 'forms.fields.symbol.label' | translate }}
        <span [class]="{ 'color-text-error': form.touched && symbol.errors && symbol.errors['required'] }">*</span>
      </label>
      <div class="flex flex-col flex-grow flex-basis-64 gap-4" [class]="{ 'w-full': deviceName() !== 'desktop' }">
        <input
          pInputText
          autocomplete="off"
          class="font-21"
          formControlName="symbol"
          id="symbol"
          minlength="1"
          maxlength="32"
          required
          type="text"
          [placeholder]="'forms.fields.symbol.placeholder' | translate"
        />
        <span class="ps-4 font-14 color-text-secondary">
          <span
            class="opacity-60"
            [ngClass]="{
              'color-text-error opacity-100': form.touched && symbol.errors && symbol.errors['required']
            }"
          >
            {{ 'forms.common.required' | translate }}
          </span>
          <span
            class="opacity-60"
            [ngClass]="{ 'color-text-error opacity-100': form.touched && symbol.errors && symbol.errors['pattern'] }"
          >
            {{ 'forms.fields.symbol.description' | translate }}
          </span>
        </span>
      </div>
    </div>

    <!-- BLOCKCHAIN -->
    <div
      class="w-full flex"
      [class]="deviceName() === 'desktop' ? 'items-center flex-grow gap-20' : 'flex-col items-start justify-centerflex-grow gap-10'"
    >
      <label class="flex flex-shrink flex-basis-33 font-21" for="blockchainId">
        {{ 'forms.fields.blockchain.label' | translate }}
        <span [class]="{ 'color-text-error': form.touched && blockchain.errors && blockchain.errors['required'] }">*</span>
      </label>
      <div class="flex flex-col flex-grow flex-basis-64 gap-4" [class]="{ 'w-full': deviceName() !== 'desktop' }">
        <p-dropdown
          [autoDisplayFirst]="false"
          [options]="blockchainOptions"
          class="font-21 flex flex-grow items-center"
          formControlName="blockchainId"
          id="blockchainId"
          optionLabel="name"
          optionValue="id"
        >
          <ng-template let-selected pTemplate="selectedItem">
            @if (isSwitchingChain()) {
              <div class="h-full flex items-center justify-start">
                <span class="font-21">{{ 'forms.fields.blockchain.switching_chain' | translate }}</span>
              </div>
            } @else if (currentBlockchain()?.unsupported) {
              <div class="h-full flex items-center justify-start">
                <span class="font-21">{{ currentBlockchain()!.name }}</span>
              </div>
            } @else if (selected) {
              <div class="h-full flex items-center justify-start gap-8">
                <img class="size-20" [alt]="selected.name" [src]="selected.logo" />
                <span class="font-21">{{ selected.name }}</span>
              </div>
            }
          </ng-template>
          <ng-template let-blockchain pTemplate="item">
            <div class="h-full flex items-center justify-start gap-8">
              <img class="size-20" [alt]="blockchain.name" [src]="blockchain.logo" />
              <span class="font-21">{{ blockchain.name }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <span class="ps-4 font-14 color-text-secondary">
          <span
            class="opacity-60"
            [ngClass]="{
              'color-text-error opacity-100': form.touched && blockchain.errors && blockchain.errors['required']
            }"
          >
            {{ 'forms.common.required' | translate }}
          </span>
          <span class="opacity-60">
            {{ 'forms.fields.blockchain.description' | translate }}
          </span>
        </span>
      </div>
    </div>

    <!-- TOTAL SUPPLY -->
    <div
      class="w-full flex"
      [class]="deviceName() === 'desktop' ? 'items-center flex-grow gap-20' : 'flex-col items-start justify-centerflex-grow gap-10'"
    >
      <label class="flex flex-shrink flex-basis-33 font-21" for="total-supply">
        {{ 'forms.fields.total_supply.label' | translate }}
        <span [class]="{ 'color-text-error': form.touched && totalSupply.errors && totalSupply.errors['required'] }">*</span>
      </label>
      <div class="flex flex-col flex-grow flex-basis-64 gap-4" [class]="{ 'w-full': deviceName() !== 'desktop' }">
        <p-inputNumber [min]="1000" [required]="true" class="font-21" formControlName="totalSupply" id="total-supply" placeholder="10000" />
        <span class="ps-4 font-14 color-text-secondary">
          <span
            class="opacity-60"
            [ngClass]="{
              'color-text-error opacity-100': form.touched && totalSupply.errors && totalSupply.errors['required']
            }"
          >
            {{ 'forms.common.required' | translate }}
          </span>
          <span
            class="opacity-60"
            [ngClass]="{
              'color-text-error opacity-100': form.touched && totalSupply.errors && (totalSupply.errors['min'] || totalSupply.errors['max'])
            }"
          >
            {{ 'forms.fields.total_supply.description' | translate }}
          </span>
        </span>
      </div>
    </div>
  </div>
</p-panel>

<app-block-ui [blocked]="!isConnected()">
  <div class="h-full flex flex-col items-center justify-center gap-14">
    <i class="pi pi-lock font-28 size-28"></i>
    <span class="font-21">{{ 'forms.disconnected.message' | translate }}</span>
    <app-connect-wallet></app-connect-wallet>
  </div>
</app-block-ui>

@switch (step()) {
  @case (Step.INFO) {
    <app-form class="max-w-1512 w-full my-28 mx-auto flex flex-col gap-28 items-center justify-center"></app-form>
  }

  @case (Step.VERIFY) {
    @defer (when step() === Step.VERIFY) {
      <app-verify class="max-w-1512 w-full my-28 mx-auto flex flex-col gap-28 items-center justify-center"></app-verify>
    }
  }

  @case (Step.RESULT) {
    @defer (when step () === Step.RESULT) {
      <app-result class="max-w-1512 w-full my-28 mx-auto flex flex-col gap-28 items-center justify-center"></app-result>
    }
  }
}

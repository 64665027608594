import type { HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { AsyncSubject, catchError, finalize, of, tap, timeout } from 'rxjs';
import { LocalStorageUtil } from '@dextools/utils';
import { AuthenticationService } from '../authentication/authentication.service';
import { ANYONE_ID } from '../../constants/authentication.constants';
import { TOKEN_TTL } from '../../constants/sync.constants';
import { Environment } from '../../models/environment.model';
import { AUTO_SYNC_FLAG, isWhitelisted } from '../../utils/sync.utils';

const LOCAL_STORAGE_LAST_SYNCED = 'lastSynced';

// Check every 2s if token is synced
const CHECK_IS_SYNCED_INTERVAL = 2_000;
const BACKOFF_INTERVAL = CHECK_IS_SYNCED_INTERVAL / 2;
// Max login backoff interval: 30s
const MAX_INTERVAL_TIME = 30_000;
// Timing out login call after 10s
const TIMEOUT_INTERVAL = 10_000;

@Injectable({
  providedIn: 'root',
})
/**
 * Service used to sync login when there's been inactivity for a period of time
 */
export class SyncService {
  private readonly _authenticationService = inject(AuthenticationService);
  private readonly _environment = inject(Environment);

  // Async 'ready' callback
  private _synced$ = new AsyncSubject<boolean>();
  // Current login call
  private _login$: Observable<unknown> | null = null;
  private _loginAttempts = 1;
  private _lastTry!: number;

  public initialize(): void {
    if (this._lastTry != null) {
      console.error('Sync: service already initialized! Cannot initialize twice. Check your code :/');
      return;
    }

    this._lastTry = Date.now();

    this._trySync();

    setInterval(() => {
      this._trySync();
    }, CHECK_IS_SYNCED_INTERVAL);
  }

  public sync(httpRequest?: HttpRequest<unknown>): Observable<boolean> {
    // if httpRequest is not defined, then it's an auto sync
    const request: HttpRequest<unknown> = httpRequest ?? ({ url: AUTO_SYNC_FLAG } as HttpRequest<unknown>);
    const isSynced = this.isSynced();

    // Whenever the auth token is not in sync (or very close to expire)
    if (!isSynced) {
      const _isWhitelisted = isWhitelisted(this._environment, request);

      // Check if an auth token sync is needed
      if (_isWhitelisted && this._shouldSync()) {
        // SYNC
        // Update last try
        this._lastTry = Date.now();

        // Increment attempts: increments backoff
        this._loginAttempts++;

        // Try to refresh logged in bearer
        this._login$ = this._authenticationService.refreshLogin().pipe(
          timeout(TIMEOUT_INTERVAL),
          tap(() => {
            // SUCCESS
            // Set observers subject value to true
            this._synced$.next(true);
            // Reset backoff
            this._loginAttempts = 0;
            // Update last sync try
            this._lastTry = Date.now();
            // Update last synced time
            LocalStorageUtil.setDate(LOCAL_STORAGE_LAST_SYNCED, new Date());
          }),
          catchError((e) => {
            // ERROR
            // Set observers subject value to false
            this._synced$.next(false);
            return of(e);
          }),
          finalize(() => {
            // COMPLETE
            // Reset syncing promise
            this._login$ = null;
            // Inform observers
            this._synced$.complete();
            this._synced$ = new AsyncSubject<boolean>();
          }),
        );
        this._login$.subscribe();

        return this._synced$;
      }

      // Check if the url is whitelisted
      if (_isWhitelisted) {
        return of(true);
      }
    }

    return this._synced$;
  }

  /**
   * Check if there is a token sync in progress
   *
   * @returns `true` in case there's a token sync in progress, `false` otherwise
   */
  public isSyncing(): boolean {
    return this._login$ != null;
  }

  /**
   * Checks:
   * - there's no sync in progress
   * - last sync token has not expired (time < ttl)
   * - auth token is present
   *
   * @returns `true` in case the token is synced, `false` otherwise
   */
  public isSynced(): boolean {
    // Last successful sync date
    const lastSynced = LocalStorageUtil.getDate(LOCAL_STORAGE_LAST_SYNCED);

    this._authenticationService.checkAuthToken();

    const userData = this._authenticationService.decryptDataUser();

    if ((userData == null || userData.id === ANYONE_ID) && !this._authenticationService.deviceId) {
      LocalStorageUtil.delete(LOCAL_STORAGE_LAST_SYNCED);
      return true;
    }

    return (
      !this.isSyncing() &&
      lastSynced != null &&
      Date.now() - lastSynced.getTime() <= TOKEN_TTL &&
      this._authenticationService.authToken != null
    );
  }

  /**
   * Checks is service should make a sync call
   *
   * @returns `true` in case the token should be synced, `false` otherwise
   */
  private _shouldSync(): boolean {
    if (this._login$ != null) {
      return false;
    }

    const lastTryDiffTime = Date.now() - this._lastTry;
    const currentTrySyncTime = BACKOFF_INTERVAL * this._getBackoffValue();

    // if (!this._environment.production) {
    //   console.log(`lastSyncDiffTime: ${lastSyncDiffTime / 1000}, currentSyncTime ${currentSyncTime / 1000}`);
    //   console.log(`Is max sync time?: ${(Date.now() - this._lastTry) > MAX_INTERVAL_TIME} - ${
    //     Date.now() - this._lastTry} > ${MAX_INTERVAL_TIME}`);
    // }

    return (
      (lastTryDiffTime > currentTrySyncTime && currentTrySyncTime <= MAX_INTERVAL_TIME) ||
      (Date.now() - this._lastTry > MAX_INTERVAL_TIME && currentTrySyncTime >= MAX_INTERVAL_TIME)
    );
  }

  /**
   * Try syncing
   *
   */
  private _trySync() {
    this.sync().subscribe();
  }

  /**
   * Get a backoff multiplier to retry another login attempt
   *
   * @returns A backoff multiplier depending on the current login attempts
   */
  private _getBackoffValue(): number {
    return Math.pow(1.5, this._loginAttempts) - 1;
  }
}

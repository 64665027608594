import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LazyCss } from '@dextools/core';

import { Theme } from '../models/theme.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /** The reference to the angular's renderer. */
  private _renderer?: Renderer2;
  /** The theme in used. */
  private _theme: Theme = Theme.DARK;
  private _document!: Document;

  public constructor(
    private readonly _lazyCss: LazyCss,
    private readonly _rendererFactory: RendererFactory2,
  ) {}

  public initialize(document: Document, appRenderer?: Renderer2) {
    this._document = document;
    this._renderer = appRenderer ?? this._rendererFactory.createRenderer(null, null);
  }

  public get theme() {
    return this._theme;
  }

  /**
   * Applies the desired theme.
   *
   * @param theme - The theme to apply.
   */
  public apply(theme: Theme) {
    if (!this._renderer) {
      return;
    }

    if (theme === Theme.DARK) {
      this._renderer.removeClass(this._document.body, Theme.LIGHT);
      this._renderer.addClass(this._document.body, Theme.DARK);
    } else {
      this._renderer.removeClass(this._document.body, Theme.DARK);
      this._renderer.addClass(this._document.body, Theme.LIGHT);
    }

    this._theme = theme;
    this._lazyCss.loadCss(this._document, 'app-theme', `${theme}.css`);
  }
}

import { HttpClient } from '@angular/common/http';
import { Environment } from '@dextools/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Translate HTTP loader factory
 *
 * @param http - Http client
 * @param environment - Environment
 *
 * @returns Translate http loader
 */
export function HttpLoaderFactory(http: HttpClient, environment: Environment) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cb=${environment.appVersion.toLowerCase().split(' ').join('.')}`);
}

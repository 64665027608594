<div class="landing max-w-1512 my-38 mx-auto px-28 flex flex-col gap-100">
  <section class="container flex items-center justify-center gap-56">
    <div class="flex items-center flex-1">
      <img class="image" src="assets/images/landing/benefits.webp" [alt]="'landing.header.benefits_alt' | translate" />
    </div>
    <div class="container flex flex-col gap-22 flex-1">
      <div class="flex items-center gap-8" routerLink="/">
        <img loading="lazy" src="assets/images/logos/logo.svg" [alt]="'landing.header.logo_alt' | translate" />
        <div class="flex items-end gap-8">
          <span class="font-22 font-bold">{{ 'landing.header.product_name' | translate }}</span>
          <span class="font-18 font-semibold color-text-secondary">by DEXTools</span>
        </div>
      </div>
      <div class="flex flex-col gap-8">
        <h2 class="font-48" [innerHTML]="'landing.header.title1' | translate"></h2>
        <h2 class="font-48" [innerHTML]="'landing.header.title2' | translate"></h2>
      </div>
      <p class="font-24">
        {{ 'landing.header.description' | translate }}
      </p>
      <ng-template [ngTemplateOutlet]="actionButtons"></ng-template>
      <div class="font-22 font-semibold text-center">
        <span>{{ 'landing.header.audit_ctdsec' | translate }} </span>
        <a href="https://ctdsec.com/audits/Cybersecurity_Audit_CTDSEC_DextoolsTokenCreator_v3.pdf" target="_blank" rel="noopener noreferrer"
          >CTDSEC</a
        >
      </div>
    </div>
  </section>

  <section class="flex flex-col items-center gap-14 text-center">
    <p class="font-18 color-text-secondary leading-tight">{{ 'landing.features.subtitle' | translate }}</p>
    <p class="mb-28 font-40 text-center leading-tight">{{ 'landing.features.title' | translate }}</p>
    <div class="container py-38 flex gap-45">
      <div class="feature py-28 px-21 flex flex-col items-center gap-20 rounded-xl">
        <img
          class="feature-image"
          loading="lazy"
          src="assets/images/landing/easy.svg"
          [alt]="'landing.features.easy.altImage' | translate"
        />
        <p class="font-24 font-semibold">{{ 'landing.features.easy.title' | translate }}</p>
        <p class="font-18 text-center leading-normal color-text-secondary">{{ 'landing.features.easy.description' | translate }}</p>
      </div>
      <div class="feature py-28 px-21 flex flex-col items-center gap-20 rounded-xl">
        <img
          class="feature-image"
          loading="lazy"
          src="assets/images/landing/customizable.svg"
          [alt]="'landing.features.customizable.altImage' | translate"
        />
        <p class="font-24 font-semibold">{{ 'landing.features.customizable.title' | translate }}</p>
        <p class="font-18 text-center leading-normal color-text-secondary">{{ 'landing.features.customizable.description' | translate }}</p>
      </div>
      <div class="feature py-28 px-21 flex flex-col items-center gap-20 rounded-xl">
        <img
          class="feature-image"
          loading="lazy"
          src="assets/images/landing/secure.svg"
          [alt]="'landing.features.secure.altImage' | translate"
        />
        <p class="font-24 font-semibold">{{ 'landing.features.secure.title' | translate }}</p>
        <p class="font-18 text-center leading-normal color-text-secondary">{{ 'landing.features.secure.description' | translate }}</p>
      </div>
    </div>
    <p-button
      class="main-action min-w-240"
      icon="pi pi-arrow-right"
      routerLink="/create"
      [label]="'landing.actions.want_token' | translate"
      [rounded]="true"
    ></p-button>
  </section>

  <section class="container flex flex-col items-center gap-56 text-center">
    <div class="flex flex-col items-center gap-22">
      <h2 class="font-40 font-semibold">{{ 'landing.visibility1.title' | translate }}</h2>
      <span class="font-18 leading-normal">{{ 'landing.visibility1.subtitle' | translate }}</span>
    </div>
    <img
      class="image"
      loading="lazy"
      src="assets/images/landing/list.webp"
      alt="Every list of tokens highlights tokens created with our Token Creator"
    />
    <img
      class="image"
      loading="lazy"
      src="assets/images/landing/socials.webp"
      alt="Socials updated highlights tokens created with our Token Creator"
    />
    <img
      class="image"
      loading="lazy"
      src="assets/images/landing/pair-explorer.webp"
      alt="Pair explorer highlights tokens created with our Token Creator"
    />
  </section>

  <section class="container flex items-center justify-center gap-56">
    <div class="flex items-center flex-1">
      <img
        class="image"
        loading="lazy"
        src="assets/images/landing/graph.webp"
        alt="Graph showing the amount of blockchains supported and monthly visits of DEXTools"
      />
    </div>
    <div class="container flex flex-col flex-1 items-start gap-22">
      <h2 class="font-48" [innerHTML]="'landing.visibility2.title' | translate"></h2>
      <p class="font-18 leading-normal">{{ 'landing.visibility2.description1' | translate }}</p>
      <p class="font-18 leading-normal">{{ 'landing.visibility2.description2' | translate }}</p>
      <ng-template [ngTemplateOutlet]="actionButtons"></ng-template>
    </div>
  </section>
</div>

<ng-template #actionButtons>
  <div class="w-full py-21 flex gap-28">
    <p-button
      class="main-action"
      icon="pi pi-arrow-right"
      routerLink="/create"
      [attr.aria-label]="'landing.actions.create' | translate"
      [label]="'landing.actions.create' | translate"
      [rounded]="true"
    ></p-button>
    <p-button
      icon="pi pi-list"
      routerLink="/my-tokens"
      severity="secondary"
      [attr.aria-label]="'landing.actions.my_tokens' | translate"
      [label]="'landing.actions.my_tokens' | translate"
      [outlined]="true"
      [rounded]="true"
    ></p-button>
  </div>
</ng-template>

// Token ttl defined in backend
// SHOULD ALWAYS BE THE SAME OR LOWER!
const MAX_TOKEN_TTL = 5 * 60_000;

// Re-login time (less than max token ttl)
// Avoids auth calls to fail while retrieving token
export const TOKEN_TTL = 0.9 * MAX_TOKEN_TTL;

// Refresh bearer time (0.9x re-login time)
// Avoids re-login calls while user is connected
export const CHECK_META_INTERVAL = 0.9 * TOKEN_TTL;

// Check account balance (retrieve plan) every 5 min
export const ACCOUNT_CHECK_INTERVAL = 5 * 60_000;

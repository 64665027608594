<p-panel class="w-full h-full flex">
  <ng-template pTemplate="header">
    <div class="flex items-center justify-start gap-14">
      <fa-icon class="opacity-60" [icon]="icons.faCoin" size="xl"></fa-icon>
      <span class="font-21 font-semibold uppercase">{{ 'forms.contract_type.header.title' | translate }}</span>
    </div>
  </ng-template>
  <div class="flex flex-col items-center gap-14">
    <div class="options grid grid-cols-3 options gap-14">
      @for (_type of types; track _type) {
        <div
          [class]="{ 'contract-type__option--selected': type() === ContractType[_type] }"
          class="contract-type__option"
          (click)="setType(ContractType[_type])"
        >
          <label class="font-14 font-semibold leading-normal text-center" style="min-height: 42px" [for]="ContractType[_type]">
            {{ getLabel(ContractType[_type]) }}
          </label>
          <p-radioButton
            [inputId]="ContractType[_type]"
            [name]="ContractType[_type]"
            [ngModel]="type()"
            [value]="ContractType[_type]"
          ></p-radioButton>
        </div>
      }
    </div>

    <p-divider align="center" class="w-full uppercase color-primary">
      {{ getSelectedLabel() }}
    </p-divider>

    <p class="font-18 text-center leading-tight">{{ getSelectedDescription() }}</p>
  </div>
</p-panel>

import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  selector: 'app-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
})
export class BlockUiComponent {
  @Input({ required: true })
  public set blocked(value: boolean) {
    this.isVisible.set(value);
  }

  protected readonly isVisible = signal(false);
}

import { Injectable, RendererFactory2 } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtil, SeoService, SettingsService } from '@dextools/core';
import type { TokenCreator } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class TokenCreatorSettingsService extends SettingsService<TokenCreator> {
  public readonly _defaultConfigValue: Partial<TokenCreator> = {
    language: LanguageUtil.supportNavigationLanguage, // the default browser's language if that matched our supported languages
    dark_theme: true,
  };
  public readonly _localStorageConfigKey = 'configApp';

  public constructor(_location: Location, _translate: TranslateService, _seoService: SeoService, _rendererFactory: RendererFactory2) {
    super(_location, _translate, _seoService, _rendererFactory);
  }
}

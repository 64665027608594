import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FormComponent } from '../../components/form/form.component';
import { ResultComponent } from '../../components/result/result.component';
import { VerifyComponent } from '../../components/verify/verify.component';
import { Step } from '../../models/steps.model';
import { StoreService } from '../../services/store.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormComponent, ResultComponent, VerifyComponent],
  selector: 'app-create',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss'],
})
export class CreatePageComponent {
  protected readonly Step = Step;
  protected readonly step = this._storeService.step;

  public constructor(private readonly _storeService: StoreService) {}
}

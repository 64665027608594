import { Injectable } from '@angular/core';

/**
 * Defer the loading and parsing of a service by lazy loading it when needed.
 *
 * This ensures that the code of such service (and all its dependencies) are bundled in a separate chunk
 * which helps to decrease the size of the main app bundle.
 *
 * Implementation taken from: https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/
 */
@Injectable({ providedIn: 'root' })
export class LazyCss {
  /**
   * Load the given CSS file by adding its corresponding `<link>` tag so that the browser loads such styles dynamically.
   *
   * @param document - The current HTML document where the `<link>` element
   * @param styleId - Id given to the `<link>` element that will be added/updated
   * @param cssFile - Full name of the CSS file containing the styles to load dynamically
   */
  public loadCss(document: Document, styleId: string, cssFile: string): void {
    const head = document.querySelectorAll('head')[0] as HTMLHeadElement;

    const themeLink = document.querySelector<HTMLLinkElement>(`#${styleId}`);
    if (themeLink) {
      // IMPORTANT: only change the `link.href´ if it's actually different from the current one
      // Otherwise the browser will download the target file again
      const themeLinkUrl = new URL(themeLink.href);
      const themeFileRegex = new RegExp(`${cssFile}$`);
      if (!themeFileRegex.test(themeLinkUrl.pathname)) {
        themeLink.href = cssFile;
      }
    } else {
      const style = document.createElement('link');
      style.id = styleId;
      style.rel = 'stylesheet';
      style.href = cssFile;

      head.append(style);
    }
  }
}

<p-selectButton
  optionLabel="label"
  optionValue="value"
  [ngModel]="mode()"
  [disabled]="!isConnected()"
  [options]="options"
  (ngModelChange)="setMode($event)"
>
  <ng-template let-item pTemplate>
    <span class="font-21 font-semibold uppercase">{{ item.label | translate }}</span>
  </ng-template>
</p-selectButton>

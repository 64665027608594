import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter, ReplaySubject, take, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import type { AppConfig, Language } from '@dextools/core';
import { AuthenticationService, Environment, LanguageUtil, SettingsService } from '@dextools/core';
import type { Chain } from '@dextools/blockchains';
import { ChainUtil } from '@dextools/blockchains';
import type { MatomoObject, MatomoPlatformType } from '../models/matomo-object.model';
import type { Resolution } from '@dextools/ui-utils';
import { DeviceService } from '@dextools/ui-utils';
import type { AnalyticsConfig } from '../models/analytics.model';
import { ANALYTICS_CONFIG } from '../models/analytics.model';
import type { InterestLinkDataToSend, ProcessLinkDataToSend } from '../models/links.model';
import { ExchangeService } from '@dextools/blockchains/services';

// Matomo Tag Manager interfaces
// See implementation guides provided by Merkle
declare global {
  interface Window {
    _mtm: MatomoObject[];
    sendView: () => void;
    sendLink: (event: InterestLinkDataToSend | ProcessLinkDataToSend) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public constructor(
    private readonly _router: Router,
    private readonly _environment: Environment,
    private readonly _authService: AuthenticationService,
    private readonly _exchangeService: ExchangeService,
    private readonly _deviceService: DeviceService,
    private readonly _settingsService: SettingsService<AppConfig>,
    private readonly _location: Location,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(ANALYTICS_CONFIG) private readonly _analyticsConfig: AnalyticsConfig,
  ) {}

  private readonly _scriptLoaded$ = new ReplaySubject<boolean>(1);
  private _previousUrl = '';
  private _platform: MatomoPlatformType = 'web';

  public get isScriptLoaded$(): Observable<boolean> {
    return this._scriptLoaded$.asObservable().pipe(take(1));
  }

  /**
   * Starts tracking.
   *
   * IMPORTANT: Should be called only once (during application startup).
   */
  public initialize(): void {
    this.isScriptLoaded$
      .pipe(
        tap(() => {
          this._platform = this._authService.isMobileApp ? 'app' : 'web';
          this._startPageViewTracking(this._router);
        }),
      )
      .subscribe();
  }

  /**
   * Should be called only once (during application startup) before the 'initialize' method is called.
   *
   * @returns Promise that resolves when the script is loaded.
   */
  public async loadScript() {
    if (isPlatformServer(this._platformId)) {
      this._scriptLoaded$.next(true);
      return 'no need to load script in SSR';
    }

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _mtm = ((window as any)._mtm = (window as any)._mtm || []);

      _mtm.push({ 'mtm.startTime': Date.now(), event: 'mtm.Start' });
      const d = this._document;
      const g = d.createElement('script');
      const s = d.querySelectorAll('script')[0];

      g.async = true;
      g.defer = true;
      // eslint-disable-next-line unicorn/prefer-ternary
      g.src = this._analyticsConfig.podContainer;
      g.addEventListener('load', () => {
        this._scriptLoaded$.next(true);
        resolve('script loaded');
      });
      g.addEventListener('error', () => {
        reject('script not loaded');
      });
      (s.parentNode as HTMLElement).insertBefore(g, s);
    });
  }

  /**
   * Sends Matomo view data (page being visited).
   *
   * @param currentUrl - Current URL.
   */
  public trackPageView(currentUrl: string): void {
    if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'local') {
      return;
    }

    const matomoObject = this._fillMatomoHierarchy(currentUrl);

    try {
      window._mtm.push(matomoObject);
      window.sendView();
    } catch (error) {
      console.warn(`Could not register view ${matomoObject.hierarchy2}`, error);
    }
  }

  /**
   * Triggers Matomo track when it detects a page change.
   *
   * @param router - Application's router
   */
  private _startPageViewTracking(router: Router) {
    router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        tap(() => {
          this.trackPageView(this._location.path());
        }),
      )
      .subscribe();
  }

  /**
   * Fill in the object that will be sent to Matomo.
   *
   * @param currentUrl - Current URL.
   * @returns Object to be sent to Matomo
   */
  private _fillMatomoHierarchy(currentUrl: string): MatomoObject {
    const displayResolution = this._deviceService.getDeviceConfig('displayResolution')() as Resolution;
    const hierarchy2 = this._getHierarchy2FromUrl(currentUrl) ?? '';
    const hierarchy3 = this._analyticsConfig.hierarchy3GetterFn ? this._analyticsConfig.hierarchy3GetterFn(currentUrl) : '';

    const mtmObject: MatomoObject = {
      platform: this._platform,
      pageName: '',
      hierarchy1: this._analyticsConfig.app,
      hierarchy2,
      hierarchy3,
      hierarchy4: '',
      hierarchy5: '',
      hierarchy6: '',
      previousPageName: this._previousUrl,
      language: this._settingsService.language,
      visualization: displayResolution === 'smallMobile' ? 'mobile' : displayResolution,
      connected: this._authService.getCurrentUserValue() ? 'connected' : 'not connected',
      chain: this._exchangeService.chain ?? ('ALL' as Chain),
    };
    mtmObject.pageName = this._getPageName(mtmObject);

    this._previousUrl = mtmObject.pageName;

    return mtmObject;
  }

  /**
   * Calculates hierarchy 2 from the current url.
   *
   * @param url - The current URL.
   *
   * @returns Chain that is the hierarchy 2 for Matomo.
   *
   * @example
   * /en/ether/pair-explorer/0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d
   * hierarchy2 is pair-explorer
   */
  private _getHierarchy2FromUrl(url: string) {
    let page: string | undefined;

    if (this._analyticsConfig.urlMappingFn) {
      page = this._analyticsConfig.urlMappingFn(url) ?? undefined;
    }

    if (page) {
      return page.replace(/-/g, ' ');
    }

    page = url
      .split('/')
      .find(
        (urlPart) =>
          urlPart &&
          !LanguageUtil.languageList.includes(urlPart as Language) &&
          !ChainUtil.allChains.map((chainData) => chainData.chain.toLowerCase()).includes(urlPart as Chain) &&
          !this._environment.app_scope.includes(urlPart),
      );

    return page?.replace(/-/g, ' ');
  }

  /**
   * Calculates page name.
   *
   * @param matomoObject - The object sent to Matomo.
   *
   * @returns Current page name.
   */
  private _getPageName(matomoObject: MatomoObject): string {
    let pageName = '';

    for (const key of Object.keys(matomoObject)) {
      if (/hierarchy(1|2)/.test(key) && matomoObject[key as 'hierarchy1']) {
        pageName += `${matomoObject[key as 'hierarchy1']}:`;
      }
    }

    return (pageName || ':').slice(0, -1); // remove last ':'
  }

  /**
   * Sends Matomo interest event link (link being pressed).
   *
   * @param event - Link detected.
   */
  public trackLink(event: InterestLinkDataToSend | ProcessLinkDataToSend): void {
    if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'local') {
      return;
    }

    try {
      window.sendLink(event);
    } catch (error) {
      console.warn(`Could not track event ${event}`, error);
    }
  }

  /**
   * Sends Matomo process event link (process step being detected).
   *
   * @param processId - Process detected.
   * @param step - Process step detected.
   */
  public trackProcessLink(processId: string, step: number): void {
    if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'local') {
      return;
    }

    const foundElements = this._analyticsConfig.processLinks.filter((element) => element.id === processId);

    if (foundElements.length > 0) {
      const foundElement = foundElements[0];
      const fullElement = { ...foundElement, processStep: foundElement.steps[step - 1].processStep };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, steps, ...elementToSend } = fullElement;

      // send elementToSend to matomo
      this.trackLink(elementToSend);
    }
  }
}

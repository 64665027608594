import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIModule } from 'primeng/blockui';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ConnectWalletComponent } from '../components/shared/connect-wallet/connect-wallet.component';
import { SettingsPanelComponent } from '../layout/settings-panel/settings-panel.component';

import { StoreService } from '../services/store.service';

import { DeviceService } from '@dextools/ui-utils';
import { RouterOutlet } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeaderComponent,
    BlockUIModule,
    CardModule,
    ConnectWalletComponent,
    FooterComponent,
    NgClass,
    SettingsPanelComponent,
    TranslateModule,
    ToastModule,
    RouterOutlet,
  ],
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public readonly deviceName = this._deviceService.getDeviceConfig('displayResolution');
  public mode = this._storeService.mode;

  public constructor(
    private readonly _storeService: StoreService,
    private readonly _deviceService: DeviceService,
  ) {}
}

import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

import { MyTokensComponent } from '../../components/my-tokens/my-tokens.component';
import { Router } from '@angular/router';
import type { CreatedToken } from '../../models/my-tokens.model';
import { BlacklistComponent } from '../../components/blacklist/blacklist.component';
import { LiquidityComponent } from '../../components/liquidity/liquidity.component';
import { SidePanelService } from '../../services/side-panel.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MyTokensComponent, NgTemplateOutlet, BlacklistComponent, LiquidityComponent],
  selector: 'app-my-tokens-page',
  templateUrl: './my-tokens-page.component.html',
  styleUrls: ['./my-tokens-page.component.scss'],
})
export class MyTokensPageComponent {
  protected token = signal<CreatedToken | null>(null);
  protected activePanel = this._sidePanelService.getPanel();

  public constructor(
    private readonly _sidePanelService: SidePanelService,
    private readonly _router: Router,
  ) {}

  protected async onAddLiquidity(token?: CreatedToken) {
    this.token.set(token ?? null);
    this._sidePanelService.setPanel('liquidity');
  }

  protected async onBlacklist(token?: CreatedToken) {
    this.token.set(token ?? null);
    this._sidePanelService.setPanel('blacklist');
  }

  protected onCreateToken() {
    this._router.navigate(['/create']);
  }
}

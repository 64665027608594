import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import type { MetaDefinition } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { Environment } from '../../models/environment.model';
import type {
  DiscordMetaData,
  FacebookMetaData,
  GeneralMetaData,
  LinkedinMetaData,
  TelegramMetaData,
  TwitterMetaData,
} from '../../models/seo.model';
import { valuesMetaTags } from '../../models/seo.model';

const OG_DESCRIPTION = 'og:description';
const TWITTER_CARD = 'twitter:card';
const OG_SITE_NAME = 'og:site_name';

let allowSEOIndexing = true;

// If the app is not running in production, we don't want to index it
if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] !== 'prod') {
  allowSEOIndexing = false;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly _titleService = inject(Title);
  private readonly _metaService = inject(Meta);
  private readonly _environment = inject(Environment);
  private readonly _document = inject<Document>(DOCUMENT);

  private readonly _titleVersion = ` | ${this._environment.appTitle}`;

  public setTitle(page: string): void {
    const title = page + this._titleVersion;
    this._titleService.setTitle(title);
  }

  /**
   * Set the meta canonical url
   *
   * @param url - canonical url
   */
  public setCanonical(url: string): void {
    const head = this._document.head;
    const element =
      this._document.querySelector<HTMLLinkElement>(`link[rel='canonical']`) ?? (this._document.createElement('link') as HTMLLinkElement);
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    // appendChind is used by the SSR process
    // eslint-disable-next-line unicorn/prefer-dom-node-append
    head.appendChild(element);
  }

  /**
   * Information collected at:
   * https://css-tricks.com/essential-meta-tags-social-media/#aa-final-markup
   *
   * @param content - The metadata to be added
   */
  public setGeneralMetaTags(content: GeneralMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this.removeGeneralMetaTag('title');
      metaTags.push({ name: 'title', content: content.title + this._titleVersion });
    }
    if (content.type) {
      this.removeGeneralMetaTag('type');
      metaTags.push({ name: 'type', content: content.type });
    }
    if (content.image) {
      this.removeGeneralMetaTag('image');
      metaTags.push({ name: 'image', content: content.image });
    }
    if (content.url) {
      this.removeGeneralMetaTag('url');
      metaTags.push({ name: 'url', content: content.url });
    }
    if (content.appUrl) {
      this.removeGeneralMetaTag('url');
      metaTags.push({ name: 'url', content: `${this._environment.appUrl}/${content.appUrl}` });
    }
    if (content.showLarge) {
      this.removeGeneralMetaTag('showLarge');
      metaTags.push({ name: TWITTER_CARD, content: content.showLarge });
    }
    if (content.description) {
      this.removeGeneralMetaTag('description');
      metaTags.push({ name: 'description', content: content.description });
    }
    if (content.siteName) {
      this.removeGeneralMetaTag('siteName');
      metaTags.push({ property: OG_SITE_NAME, content: content.siteName });
    }
    if (content.altCard) {
      this.removeGeneralMetaTag('altCard');
      metaTags.push({ name: 'twitter:image:alt', content: content.altCard });
    }
    if (content.fbAppId) {
      this.removeGeneralMetaTag('fbAppId');
      metaTags.push({ property: 'fb:app_id', content: content.fbAppId });
    }
    if (content.twtAppId) {
      this.removeGeneralMetaTag('twtAppId');
      metaTags.push({ name: 'twitter:site', content: content.twtAppId });
    }
    if (content.robots) {
      this.removeGeneralMetaTag('robots');
      // SEO indexing is allowed only in prod. In other environments, the 'robots' tag is set to 'noindex, nofollow' and must be kept
      metaTags.push({ name: 'robots', content: allowSEOIndexing ? content.robots : 'noindex, nofollow' });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  /**
   * Information collected at:
   * https://samvloeberghs.be/posts/better-sharing-on-social-media-platforms-with-angular-universal/#facebook
   *
   * @param content - The metadata to be added
   */
  public setFacebookTags(content: FacebookMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this.removeFacebookTag('title');
      metaTags.push({ property: 'og:title', content: content.title + this._titleVersion });
    }
    if (content.description) {
      this.removeFacebookTag('description');
      metaTags.push({ property: OG_DESCRIPTION, content: content.description });
    }
    if (content.url) {
      this.removeFacebookTag('url');
      metaTags.push({ property: 'og:url', content: content.url });
    }
    if (content.appUrl) {
      this.removeFacebookTag('url');
      metaTags.push({ property: 'og:url', content: `${this._environment.appUrl}/${content.appUrl}` });
    }
    if (content.image) {
      this.removeFacebookTag('altImg');
      metaTags.push({ property: 'og:image', content: content.image });
    }
    if (content.altImage) {
      this.removeFacebookTag('altImg');
      metaTags.push({ property: 'og:image:alt', content: content.altImage });
    }
    if (content.height) {
      this.removeFacebookTag('width');
      metaTags.push({ property: 'og:image:height', content: content.height });
    }
    if (content.width) {
      this.removeFacebookTag('width');
      metaTags.push({ property: 'og:image:width', content: content.width });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  /**
   * Information collected at:
   * https://samvloeberghs.be/posts/better-sharing-on-social-media-platforms-with-angular-universal/#linkedin
   *
   * @param content - The metadata to be added
   */
  public setLinkedinTags(content: LinkedinMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this.removeLinkedinTag('title');
      metaTags.push({ property: 'og:title', content: content.title + this._titleVersion });
    }
    if (content.type) {
      this.removeLinkedinTag('type');
      metaTags.push({ property: 'og:type', content: content.type });
    }
    if (content.image) {
      this.removeLinkedinTag('image');
      metaTags.push({ property: 'og:image', content: content.image });
    }
    if (content.description) {
      this.removeLinkedinTag('description');
      metaTags.push({ property: OG_DESCRIPTION, content: content.description });
    }
    if (content.author) {
      this.removeLinkedinTag('author');
      metaTags.push({ name: 'author', content: content.author });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  /**
   * Information collected at:
   * https://samvloeberghs.be/posts/better-sharing-on-social-media-platforms-with-angular-universal/#twitter
   *
   * @param content - The metadata to be added
   */
  public setTwitterTags(content: TwitterMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this._removeMetaTag(`name='twitter:title'`);
      metaTags.push({ name: 'twitter:title', content: content.title + this._titleVersion });
    }
    if (content.description) {
      this._removeMetaTag(`name='twitter:description'`);
      metaTags.push({ name: 'twitter:description', content: content.description });
    }
    if (content.image) {
      this._removeMetaTag(`name='twitter:image'`);
      metaTags.push({ name: 'twitter:image', content: content.image });
    }
    if (content.altImage) {
      this._removeMetaTag(`name='twitter:image:alt'`);
      metaTags.push({ name: 'twitter:image:alt', content: content.altImage });
    }
    if (content.site) {
      this._removeMetaTag(`name='twitter:site'`);
      metaTags.push({ name: 'twitter:site', content: content.site });
    }
    if (content.creator) {
      this._removeMetaTag(`name='twitter:creator'`);
      metaTags.push({ name: 'twitter:creator', content: content.creator });
    }
    if (content.card) {
      this._removeMetaTag(`name='twitter:card'`);
      metaTags.push({ name: TWITTER_CARD, content: content.card });
    }
    if (content.url) {
      this._removeMetaTag(`name='twitter:url`);
      metaTags.push({ name: 'twitter:url', content: content.url });
    }
    if (content.appUrl) {
      this._removeMetaTag(`name='twitter:url`);
      metaTags.push({ name: 'twitter:url', content: `${this._environment.appUrl}/${content.appUrl}` });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  /**
   * Information collected at:
   * https://www.reddit.com/r/discordapp/comments/82p8i6/a_basic_tutorial_on_how_to_get_the_most_out_of/
   *
   * @param content - The metadata to be added
   */
  public setDiscordTags(content: DiscordMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this.removeDiscordTag('title');
      metaTags.push({ property: 'og:title', content: content.title + this._titleVersion });
    }
    if (content.siteName) {
      this.removeDiscordTag('siteName');
      metaTags.push({ property: OG_SITE_NAME, content: content.siteName });
    }
    if (content.description) {
      this.removeDiscordTag('description');
      metaTags.push({ property: OG_DESCRIPTION, content: content.description });
    }
    if (content.image) {
      this.removeDiscordTag('image');
      metaTags.push({ property: 'og:image', content: content.image });
    }
    if (content.themeColor) {
      this.removeDiscordTag('themeColor');
      metaTags.push({ name: 'theme-color', content: content.themeColor });
    }
    if (content.card) {
      this.removeDiscordTag('card');
      metaTags.push({ name: TWITTER_CARD, content: content.card });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  /**
   * Information collected at:
   * https://stackoverflow.com/a/30160564
   *
   * @param content - The metadata to be added
   */
  public setTelegramTags(content: TelegramMetaData): void {
    const metaTags: MetaDefinition[] = [];
    if (content.title) {
      this.removeTelegramTag('title');
      metaTags.push({ property: 'og:title', content: content.title + this._titleVersion });
    }
    if (content.siteName) {
      this.removeTelegramTag('siteName');
      metaTags.push({ property: OG_SITE_NAME, content: content.siteName });
    }
    if (content.description) {
      this.removeTelegramTag('description');
      metaTags.push({ property: OG_DESCRIPTION, content: content.description });
    }
    if (content.image) {
      this.removeTelegramTag('image');
      metaTags.push({ property: 'og:image', content: content.image });
    }
    if (metaTags.length > 0) {
      this._metaService.addTags(metaTags, false);
    }
  }

  private _removeMetaTag(value: string) {
    this._metaService.removeTag(value);
  }

  public removeGeneralMetaTag(tag: keyof typeof valuesMetaTags.generalMetaTag): void {
    // the 'robots' tag should only be removed when SEO indexing is allowed (only prod)
    // in non-prod environments, the 'robots' tag is set to 'noindex, nofollow' and must be kept
    if (tag === 'robots' && !allowSEOIndexing) {
      return;
    }
    this._removeMetaTag(valuesMetaTags.generalMetaTag[tag]);
  }

  public removeFacebookTag(tag: keyof typeof valuesMetaTags.facebook): void {
    this._removeMetaTag(valuesMetaTags.facebook[tag]);
  }

  public removeLinkedinTag(tag: keyof typeof valuesMetaTags.linkedin): void {
    this._removeMetaTag(valuesMetaTags.linkedin[tag]);
  }

  public removeTwitterTag(tag: keyof typeof valuesMetaTags.twitter): void {
    this._removeMetaTag(valuesMetaTags.twitter[tag]);
  }

  public removeDiscordTag(tag: keyof typeof valuesMetaTags.discord): void {
    this._removeMetaTag(valuesMetaTags.discord[tag]);
  }

  public removeTelegramTag(tag: keyof typeof valuesMetaTags.telegram): void {
    this._removeMetaTag(valuesMetaTags.telegram[tag]);
  }
}

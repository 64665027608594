import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectButtonModule } from 'primeng/selectbutton';

import { Mode } from '../../models/mode.model';
import { StoreService } from '../../services/store.service';
import { TokenCreatorService } from '../../services/token-creator.service';
import { toSignal } from '@angular/core/rxjs-interop';

interface ModeOption {
  label: string;
  value: Mode;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, SelectButtonModule, TranslateModule],
  selector: 'app-contract-mode',
  templateUrl: './mode.component.html',
  styleUrls: ['./mode.component.scss'],
})
export class ContractModeComponent {
  protected isConnected = toSignal(this._tokenCreatorService.account$);
  protected mode = this._storeService.mode;
  protected options: [ModeOption, ModeOption] = [
    { label: 'modes.basic', value: Mode.BASIC },
    { label: 'modes.advanced', value: Mode.ADVANCED },
  ];

  public constructor(
    private readonly _storeService: StoreService,
    private readonly _tokenCreatorService: TokenCreatorService,
  ) {}

  protected setMode(mode: Mode) {
    this._storeService.setMode(mode);
  }
}

<p-card>
  <p-table class="my-tokens" [scrollable]="true" [value]="tokens()">
    <ng-template pTemplate="caption">
      <div [class]="deviceName() === 'mobile' ? 'flex flex-col gap-14' : 'flex items-center justify-between'">
        <div class="flex items-center gap-22">
          <p-button
            class="uppercase"
            icon="pi pi-plus"
            [label]="deviceName() !== 'mobile' ? ('my_tokens.actions.create_token' | translate) : ''"
            [outlined]="true"
            [rounded]="true"
            (click)="onCreateToken()"
          >
          </p-button>
          <p-button
            severity="primary"
            [disabled]="tokens().length === 0"
            [label]="deviceName() !== 'mobile' ? ('my_tokens.actions.add_liquidity' | translate) : ''"
            [outlined]="true"
            [rounded]="true"
            (click)="onAddLiquidity()"
          >
            <fa-icon [class]="{ 'pe-8': deviceName() !== 'mobile' }" [icon]="icons.faMoneyBillTransfer" size="lg"></fa-icon>
          </p-button>
          <p-button
            icon="pi pi-ban"
            severity="primary"
            [disabled]="tokens().length === 0"
            [label]="deviceName() !== 'mobile' ? ('my_tokens.actions.blacklist' | translate) : ''"
            [outlined]="true"
            [rounded]="true"
            (click)="onBlacklist()"
          >
          </p-button>
        </div>
        <p-dropdown
          class="min-w-120"
          id="blockchain"
          optionLabel="name"
          optionValue="id"
          [options]="blockchainOptions"
          [ngModel]="selectedBlockchain()"
          (onChange)="selectBlockchain($event.value)"
        >
          <ng-template let-selected pTemplate="selectedItem">
            @if (isSwitchingChain()) {
              <div class="h-full flex items-center justify-start">
                <span>{{ 'my_tokens.filters.switching_chain' | translate }}</span>
              </div>
            } @else if (blockchain()?.unsupported) {
              <div class="h-full flex items-center justify-start">
                <span>{{ blockchain()!.name }}</span>
              </div>
            } @else if (selected) {
              <div class="flex gap-14 items-center">
                <img class="size-24" [alt]="selected.name" [src]="selected.logo" />
                <span>{{ selected.name }}</span>
              </div>
            }
          </ng-template>
          <ng-template let-blockchain pTemplate="item">
            <div class="flex gap-14 items-center">
              <img class="size-24" [alt]="blockchain.name" [src]="blockchain.logo" />
              <span>{{ blockchain.name }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="min-w-200 px-7 py-14 font-14 font-bold uppercase" pSortableColumn="name" pFrozenColumn>
          {{ 'my_tokens.header.token' | translate }}
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th class="min-w-180 px-7 py-14 font-14 font-bold uppercase" pSortableColumn="totalSupply">
          {{ 'my_tokens.header.total_supply' | translate }}
          <p-sortIcon field="totalSupply"></p-sortIcon>
        </th>
        <th class="min-w-200 px-7 py-14 font-14 font-bold uppercase">
          {{ 'my_tokens.header.contract' | translate }}
        </th>
        <th class="min-w-180 px-7 py-14 font-14 font-bold uppercase">{{ 'my_tokens.header.socials' | translate }} </th>
        <th class="min-w-340 px-7 py-14 font-14 font-bold uppercase">{{ 'my_tokens.header.actions' | translate }} </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-token>
      @if (isLoading()) {
        <!-- 4 loading slots by default, as it is neither too big nor small number -->
        @for (i of [0, 1, 2, 3]; track 'loading-skeleton-' + $index) {
          <tr>
            <td class="px-7 py-14 flex items-center gap-24">
              <p-skeleton shape="circle" size="3rem"></p-skeleton>
              <div class="flex flex-col gap-8 flex-1">
                <p-skeleton width="75%"></p-skeleton>
                <p-skeleton width="50%"></p-skeleton>
              </div>
            </td>
            <td class="px-7 py-14 items-center"><p-skeleton width="80%" height="2rem"></p-skeleton></td>
            <td class="px-7 py-14 flex items-center">
              <p-skeleton shape="circle" size="3rem"></p-skeleton>
              <p-skeleton class="w-full" shape="rounded" height="2rem"></p-skeleton>
            </td>
            <td class="px-7 py-14 items-center">
              <p-skeleton shape="rounded" width="80%" height="2rem"></p-skeleton>
            </td>
            <td class="px-7 py-14">
              <div class="w-full flex gap-14">
                <p-skeleton class="w-full" shape="rounded" height="2rem"></p-skeleton>
                <p-skeleton class="w-full" shape="rounded" height="2rem"></p-skeleton>
              </div>
            </td>
          </tr>
        }
      } @else {
        <tr>
          <td class="px-7 py-14">
            <div class="flex items-center gap-24">
              <div class="relative size-38">
                @if (token.logo) {
                  <img class="size-38 rounded-full" [src]="token.logo" [alt]="token.name + ' logo'" />
                } @else {
                  <span
                    class="size-38 py-4 px-12 flex items-center justify-center bg-gray-500 font-24 font-semibold rounded-full uppercase"
                  >
                    {{ token.symbol.slice(0, 1) }}
                  </span>
                }
                <app-verified-logo class="verified-logo"></app-verified-logo>
              </div>
              <div class="flex flex-col gap-2">
                <span class="font-14 font-semibold">{{ token.name }}</span>
                <span class="font-14 uppercase">{{ token.symbol }}</span>
              </div>
            </div>
          </td>
          <td class="px-7 py-14 font-18">${{ token.totalSupply | appRoundBigNumber }}</td>
          <td class="px-7 py-14">
            <div
              #t1="tippy"
              class="flex flex-row gap-8 items-center cursor-pointer"
              tpVariation="tooltip"
              [tpAnimation]="false"
              [tp]="'common.tooltips.copy_to_clipboard' | translate"
              [tpIsLazy]="true"
              (click)="copyToClipboard($event, token.contract, t1)"
            >
              <p-chip [label]="token.contract | addressEllipsis" [image]="blockchainsConfig[token.blockchainId].logo"></p-chip>
              <fa-icon class="color-text-secondary" [icon]="icons.faCopy" size="xl"></fa-icon>
            </div>
          </td>
          <td class="px-7 py-14">
            @if (hasSocials(token)) {
              <div class="flex gap-14">
                @for (social of getSocialKeys(token); track social) {
                  <a class="color-text-primary" rel="noopener" target="_blank" [href]="token.socials[social]">
                    @if (socialIcons[social]; as icon) {
                      <fa-icon
                        [icon]="icon"
                        class="cursor-pointer"
                        size="xl"
                        tpVariation="tooltip"
                        [tpAnimation]="false"
                        [tp]="social"
                        [tpIsLazy]="true"
                      ></fa-icon>
                    }
                  </a>
                }
              </div>
            } @else {
              <a
                class="add-socials flex gap-8 items-center justify-center"
                href="https://docs.google.com/forms/d/e/1FAIpQLSd1BAqjAl9nntlS2mOk76tE0Q-dEf-AT1bUblDXikjZ-PNP1Q/viewform"
                target="_blank"
                rel="noopener"
              >
                <fa-icon [icon]="icons.faShareAlt" size="lg"></fa-icon>
                <span class="uppercase font-bold">{{ 'my_tokens.actions.add_socials' | translate }}</span>
              </a>
            }
          </td>
          <td class="px-7 py-14">
            <div class="flex gap-14">
              <p-button
                icon="pi pi-money-bill"
                severity="primary"
                [label]="'my_tokens.actions.add_liquidity' | translate"
                [outlined]="true"
                [rounded]="true"
                (click)="onAddLiquidity(token)"
              ></p-button>
              <p-button
                icon="pi pi-ban"
                severity="primary"
                [disabled]="!token.hasAntibot"
                [label]="'my_tokens.actions.blacklist' | translate"
                [outlined]="true"
                [rounded]="true"
                (click)="onBlacklist(token)"
              ></p-button>
            </div>
          </td>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr class="my-21">
        <td class="px-7 py-14" colspan="5">
          @if (!!account()) {
            <span class="font-16">{{ 'my_tokens.empty' | translate }}</span>
          } @else {
            <span class="font-16">{{ 'my_tokens.disconnected' | translate }}</span>
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

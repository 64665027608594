import { CommonUtil, LocalStorageUtil } from '@dextools/utils';
import { Language, LanguageNavigation } from '../models/config.model';

export class LanguageUtil {
  /**
   * Gets the language that best matches the one of the browser (in case such language is supported, otherwise English is returned by default)
   *
   * @returns The language that best matches the one of the browser
   */
  public static get supportNavigationLanguage(): Language {
    try {
      return this.languageList.find((item) => navigator.language === LanguageNavigation[item]) ?? Language.EN;
    } catch (error) {
      console.warn(`'navigator' object not available`, `Error:${error}`);
      return Language.EN;
    }
  }

  /**
   * Returns the language configured in localstorage or in the browser
   *
   * @param localStorageKey - The key used to store the language in localstorage
   *
   * @returns The language configured in localstorage or in the browser
   */
  public static getCurrentStoredLanguage(localStorageKey: string): Language {
    const configApp = JSON.parse(LocalStorageUtil.getString(localStorageKey) as string);
    if (configApp?.language) {
      return configApp.language;
    }
    return this.supportNavigationLanguage;
  }

  public static get languageList(): Language[] {
    return CommonUtil.convertEnumToArray(Language);
  }
}

<div class="flex flex-col" [ngClass]="['h-dvh', 'contract-' + mode(), deviceName()]">
  <app-header #header></app-header>
  <main class="relative flex flex-col items-center mx-14 my-28">
    @defer (on idle) {
      <p-toast [life]="5000" position="top-right"></p-toast>
    }
    @defer (prefetch on idle; on hover(header); on interaction(header)) {
      <app-settings-panel></app-settings-panel>
    }
    <router-outlet></router-outlet>
  </main>
  <app-footer class="mt-auto"></app-footer>
</div>

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SettingsService } from '@dextools/core';
import type { TokenCreator } from './models/config.model';
import { Theme } from './models/theme.model';
import { ThemeService } from './services/theme.service';
import { DeviceService } from '@dextools/ui-utils';
import { LayoutComponent } from './layout/layout.component';

@Component({
  selector: 'app-root',
  template: '<app-layout></app-layout>',
  standalone: true,
  imports: [LayoutComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public constructor(
    @Inject(DOCUMENT) document: Document,
    private readonly _settingsService: SettingsService<TokenCreator>,
    private readonly _themeService: ThemeService,
    private readonly _deviceService: DeviceService,
  ) {
    this._settingsService.initialize(document);
    this._themeService.initialize(document);
    this._deviceService.initialize();
  }

  public ngOnInit() {
    this._settingsService.getConfigChanged$('dark_theme').subscribe((isDarkTheme) => {
      this._themeService.apply(isDarkTheme ? Theme.DARK : Theme.LIGHT);
    });
  }
}

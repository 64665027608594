import { Location } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom, RendererFactory2 } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { CORE_HTTP_INTERCEPTOR_PROVIDERS, Environment, SeoService, SettingsService } from '@dextools/core';
import { BREAKPOINTS_CONFIG } from '@dextools/ui-utils';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AppComponent } from './app/app.component';
import { AppInitService } from './app/app-init.service';
import { appRoutes } from './app/app.routes';
import { TokenCreatorSettingsService } from './app/services/settings.service';
import { HttpLoaderFactory } from './app/translation.config';
import { environment } from './environments/environment';
import { ANALYTICS_CONFIG } from '@dextools/analytics';

/**
 *
 * Factory function to instantiate the settings service
 *
 * @param location - The location service
 * @param translate - The translation service
 * @param seoService - The SEO service
 * @param rendererFactory - The renderer factory
 *
 * @returns TokenCreatorSettingsService The settings service
 */
export function settingsServiceFactory(
  location: Location,
  translate: TranslateService,
  seoService: SeoService,
  rendererFactory: RendererFactory2,
) {
  return new TokenCreatorSettingsService(location, translate, seoService, rendererFactory);
}

/**
 * Factory function to initialize the application.
 *
 * @param initService - The app init.
 * @returns Observable<unknown> An observable that executes when the application is initialized.
 */
function appInitFactory(initService: AppInitService) {
  return () => initService.init();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(appRoutes),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, Environment],
        },
      }),
    ),
    { provide: Environment, useValue: environment },
    { provide: SettingsService, useFactory: settingsServiceFactory, deps: [Location, TranslateService, SeoService, RendererFactory2] },
    Location,
    { provide: APP_INITIALIZER, useFactory: appInitFactory, deps: [AppInitService], multi: true },
    ...CORE_HTTP_INTERCEPTOR_PROVIDERS,
    MessageService,
    provideTippyConfig({
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    {
      provide: BREAKPOINTS_CONFIG,
      useValue: {
        mobile: 'screen and (max-width: 767px)',
        tablet: 'screen and (max-width: 1023px)',
        desktop: 'screen and (min-width: 1024px)',
      },
    },
    {
      provide: ANALYTICS_CONFIG,
      useValue: {
        app: process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod' ? 'token-creator' : `token-creator${environment.app_scope.slice(1)}`,
        urlMappingFn: getPageFromUrl,
        podContainer: 'https://matomo.dextools.io/js/container_ZsXkrm6t.js',
        // process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod'
        //   ? 'https://matomo.dextools.io/js/container_ZsXkrm6t.js'
        //   : 'https://matomo.dextools.io/js/container_ZsXkrm6t.js', //añadir url nueva para prod cuando este disponible
      },
    },
  ],
})
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch((error) => {
    console.log('Could not bootstrap the application', error);
  });

/**
 * Extrae la parte final de una URL después del código de idioma.
 *
 * @param url - La URL de la cual se va a extraer la parte final.
 * @returns - La parte de la URL después del idioma, o `null` si no coincide.
 */
export function getPageFromUrl(url: string): string {
  const regex = /[a-z]{2}\/([^/]+)/;
  const match = url.match(regex);
  return match ? match[1] : 'home';
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { Exchange } from '@dextools/blockchains';
import { EXCHANGE_UNKNOWN, Chain, ExchangeUtil } from '@dextools/blockchains';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  private _exchange: Exchange = EXCHANGE_UNKNOWN;
  private _chain: Chain | null = null;
  public exchange$ = new BehaviorSubject<Exchange>(this._exchange);
  public chain$ = new BehaviorSubject<Chain | null>(this._chain);

  public get exchange() {
    return this._exchange;
  }

  public set exchange(exchange) {
    // Only emit when exchange change
    if (this._exchange !== exchange) {
      this._exchange = exchange;
      this.exchange$.next(exchange);
    }

    if (this.chain != null) {
      // Update chain on exchange change (i.e. on first load components)
      const chainExchanges = ExchangeUtil.getExchangesFromChain(this.chain);
      const currentChainIncludesExchange = !!chainExchanges.some(
        (exchangeItem) => exchangeItem.name.toLowerCase() === exchange.toLowerCase(),
      );
      if (this.exchange !== EXCHANGE_UNKNOWN && !currentChainIncludesExchange) {
        console.warn(`Current exchange ${this.exchange} does not exist in chain ${this.chain}`);
      }
    }
  }

  public get exchangeApi() {
    return ExchangeUtil.getExchangeApiFromExchange(this.exchange, this.chain);
  }

  public get chain() {
    return this._chain;
  }

  public set chain(chain) {
    // Only emit on chain change
    if (this._chain !== chain) {
      this._chain = chain;
      this.chain$.next(chain);
      const chainExchangeList = ExchangeUtil.chainExchangeList[(chain as Chain) ?? Chain.Ethereum] ?? [];

      if (chainExchangeList.length > 0) {
        // set the default exchange (if current one does not belong to the new chain)
        const chainExchanges = ExchangeUtil.getExchangesFromChain(chain ?? Chain.Ethereum);
        if (!chainExchanges.some((exchange) => exchange.name.toLowerCase() === this.exchange.toLowerCase())) {
          this.exchange = ExchangeUtil.getDefaultExchange(chain ?? Chain.Ethereum);
        }
      }
    }
  }

  public get isBsc() {
    return this.chain === Chain.Binance;
  }

  public get isEthereum() {
    return this.chain === Chain.Ethereum;
  }
}

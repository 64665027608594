import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { DeviceService } from '@dextools/ui-utils';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { filter } from 'rxjs/operators';

import { UserMenuComponent } from './user-menu/user-menu.component';
import { SettingsPanelService } from '../settings-panel/settings-panel.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, FontAwesomeModule, NgTemplateOutlet, RouterLink, TranslateModule, UserMenuComponent],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  protected readonly icons = {
    faCog,
  };
  protected readonly deviceName = this._deviceService.getDeviceConfig('displayResolution');
  protected readonly isCreateLinkActive = computed(() => this._activeUrl().includes('create'));
  protected readonly isMyTokensLinkActive = computed(() => this._activeUrl().includes('my-tokens'));

  private readonly _activeUrl = signal(window.location.pathname);

  public constructor(
    private readonly _deviceService: DeviceService,
    private readonly _settingsPanelService: SettingsPanelService,
    private readonly _router: Router,
  ) {
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((value) => {
      this._activeUrl.set((value as NavigationEnd).url);
    });
  }

  protected openSettings() {
    this._settingsPanelService.showSettingsPanel();
  }
}

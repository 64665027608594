import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DeviceService } from '@dextools/ui-utils';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';

import { BlockUiComponent } from '../block-ui/block-ui.component';
import { ConnectWalletComponent } from '../connect-wallet/connect-wallet.component';
import { FormService } from '../../../services/form.service';
import { StoreService } from '../../../services/store.service';
import { TokenCreatorService } from '../../../services/token-creator.service';
import { NAME_REGEX } from '../../../utils/regexes.utils';

import type { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-token-info',
  standalone: true,
  imports: [
    ButtonModule,
    BlockUiComponent,
    ConnectWalletComponent,
    DropdownModule,
    FontAwesomeModule,
    InputNumberModule,
    InputTextModule,
    NgClass,
    PanelModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './token-info.component.html',
  styleUrl: './token-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenInfoComponent implements OnDestroy {
  protected readonly icons = {
    faPenToSquare,
  };
  protected readonly blockchainOptions = Object.values(this._tokenCreatorService.blockchainsConfig);

  protected readonly currentBlockchain = toSignal(this._tokenCreatorService.blockchain$);
  protected readonly isConnected = toSignal(this._tokenCreatorService.account$);
  protected readonly deviceName = this._deviceService.getDeviceConfig('displayResolution');
  protected readonly isSwitchingChain = signal(false);

  public constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _destroyRef: DestroyRef = inject(DestroyRef),
    private readonly _deviceService: DeviceService,
    private readonly _formService: FormService,
    private readonly _storeService: StoreService,
    private readonly _tokenCreatorService: TokenCreatorService,
  ) {
    const token = this._storeService.token();

    this._formService.form.addControl(
      'name',
      new FormControl<string>(token.name, { nonNullable: true, validators: [Validators.required, Validators.pattern(NAME_REGEX)] }),
    );
    this._formService.form.addControl(
      'symbol',
      new FormControl<string>(token.symbol, {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(NAME_REGEX)],
      }),
    );
    this._formService.form.addControl(
      'blockchainId',
      new FormControl<number>(token.blockchainId, { nonNullable: true, validators: [Validators.required] }),
    );
    this._formService.form.addControl(
      'totalSupply',
      new FormControl<number>(token.totalSupply, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1_000), Validators.max(Math.pow(10, 31))],
      }),
    );

    this._formService.onInvalid$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._cdRef.detectChanges();
    });

    this._tokenCreatorService.blockchain$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((blockchain) => {
      if (blockchain) {
        this._formService.form.patchValue({ blockchainId: blockchain.id });
        this._storeService.updateToken({ blockchainId: blockchain.id });
      }
    });

    this.blockchain.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async (value) => {
      const blockchain = this.currentBlockchain();

      if (blockchain && blockchain.id !== value) {
        if (this.blockchain.value === 0) {
          this._formService.form.patchValue({ blockchainId: blockchain.id });
          return;
        }

        this.isSwitchingChain.set(true);

        try {
          await this._tokenCreatorService.selectNetwork(value);
        } catch (error) {
          // Revert selected blockchain to the previous one if the user rejected switching networks in wallet provider
          this._formService.form.patchValue({ blockchainId: blockchain.id });
          console.error(error);
        }

        this.isSwitchingChain.set(false);
      }
    });
  }

  public ngOnDestroy(): void {
    this._formService.form.removeControl('name');
    this._formService.form.removeControl('symbol');
    this._formService.form.removeControl('blockchainId');
    this._formService.form.removeControl('totalSupply');
  }

  protected get form() {
    return this._formService.form;
  }

  protected get name() {
    return this._formService.form.get('name')!;
  }

  protected get symbol() {
    return this._formService.form.get('symbol')!;
  }

  protected get blockchain() {
    return this._formService.form.get('blockchainId')!;
  }

  protected get totalSupply() {
    return this._formService.form.get('totalSupply')!;
  }
}

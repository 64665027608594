export interface GeneralMetaData {
  title?: string;
  type?: string;
  image?: string;
  url?: string;
  appUrl?: string;
  description?: string;
  siteName?: string;
  showLarge?: string;
  altCard?: string;
  fbAppId?: string;
  twtAppId?: string;
  robots?: string;
}

export interface FacebookMetaData {
  title?: string;
  description?: string;
  url?: string;
  appUrl?: string;
  image?: string;
  altImage?: string;
  height?: string;
  width?: string;
}
export interface LinkedinMetaData {
  title?: string;
  type?: string;
  image?: string;
  description?: string;
  author?: string;
}

export interface TwitterMetaData {
  title?: string;
  description?: string;
  image?: string;
  altImage?: string;
  site?: string;
  card?: string;
  creator?: string;
  url?: string;
  appUrl?: string;
}

export interface DiscordMetaData {
  title?: string;
  siteName?: string;
  description?: string;
  image?: string;
  themeColor?: string;
  card?: string;
}

export interface TelegramMetaData {
  title?: string;
  siteName?: string;
  description?: string;
  image?: string;
}

export const valuesMetaTags = {
  generalMetaTag: {
    title: `name='title'`,
    type: `name='type'`,
    image: `name='image'`,
    url: `name='url'`,
    description: `name='description'`,
    siteName: `property='og:site_name'`,
    showLarge: `name='twitter:card'`,
    altCard: `name='twitter:image:alt'`,
    fbAppId: `property='fb:app_id'`,
    twtAppId: `name='twitter:site'`,
    robots: `name='robots'`,
  },
  facebook: {
    title: `property='og:title'`,
    description: `property='og:description'`,
    url: `property='og:url'`,
    image: `property='og:image'`,
    altImg: `property='og:image:alt'`,
    height: `property='og:image:height'`,
    width: `property='og:image:width'`,
  },
  linkedin: {
    title: `property='og:title'`,
    type: `property='og:type'`,
    image: `property='og:image'`,
    description: `property='og:description'`,
    author: `name='author'`,
  },
  twitter: {
    title: `name='twitter:title'`,
    description: `name='twitter:description'`,
    image: `name='twitter:image'`,
    altImage: `name='twitter:image:alt'`,
    site: `name='twitter:site'`,
    card: `name='twitter:creator'`,
    creator: `name='twitter:card'`,
    url: `name='twitter:url'`,
  },
  discord: {
    title: `property='og:title'`,
    siteName: `property='og:site_name'`,
    description: `property='og:description'`,
    image: `property='og:image'`,
    themeColor: `name='theme-color'`,
    card: `name='twitter:card'`,
  },
  telegram: {
    title: `property='og:title'`,
    siteName: `property='og:site_name'`,
    description: `property='og:description'`,
    image: `property='og:image'`,
  },
};

<header class="header flex items-center justify-between gap-28">
  @if (deviceName() === 'mobile') {
    <div class="w-full flex flex-col justify-center gap-4">
      <div class="flex items-center justify-between">
        <ng-template [ngTemplateOutlet]="logo"></ng-template>
        <div class="flex items-center gap-20">
          <fa-icon class="settings" [icon]="icons.faCog" (click)="openSettings()"></fa-icon>
          <app-user-menu></app-user-menu>
        </div>
      </div>
      <ng-template [ngTemplateOutlet]="navigation"></ng-template>
    </div>
  } @else {
    <div class="flex items-center gap-28">
      <ng-template [ngTemplateOutlet]="logo"></ng-template>
      <ng-template [ngTemplateOutlet]="navigation"></ng-template>
    </div>
    <ng-template [ngTemplateOutlet]="actions"></ng-template>
  }
</header>

<ng-template #logo>
  <div class="flex items-center gap-8 cursor-pointer" routerLink="/">
    <img class="brand-logo" src="assets/images/logos/logo.svg" [alt]="'header.logo.alt' | translate" />
    <span class="brand-name font-semibold">{{ 'header.logo.name' | translate }}</span>
  </div>
</ng-template>

<ng-template #navigation>
  <nav class="flex items-center justify-start flex-grow">
    <a class="link font-semibold uppercase" routerLink="/create" [class]="{ active: isCreateLinkActive() }">
      {{ 'header.navigation.create' | translate }}
    </a>
    <a class="link font-semibold uppercase" routerLink="/my-tokens" [class]="{ active: isMyTokensLinkActive() }">
      {{ 'header.navigation.my_tokens' | translate }}
    </a>
  </nav>
</ng-template>

<ng-template #actions>
  <div class="flex items-center justify-end gap-22">
    <fa-icon class="settings" [icon]="icons.faCog" (click)="openSettings()"></fa-icon>
    <app-user-menu></app-user-menu>
  </div>
</ng-template>

import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoin } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';

import { Type } from '../../models/type.model';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-contract-type',
  standalone: true,
  imports: [DividerModule, FontAwesomeModule, FormsModule, PanelModule, RadioButtonModule, TranslateModule],
  templateUrl: './contract-type.component.html',
  styleUrls: ['./contract-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractTypeComponent {
  protected readonly ContractType = Type;
  protected readonly icons = {
    faCoin,
  };

  protected readonly lowerCasedType = computed(() => this.type().toLowerCase());
  protected type = this._storeService.type;
  protected types: (keyof typeof Type)[] = ['STANDARD', 'BURN', 'BABY', 'LIQUIDITY_GENERATOR', 'LIQUIDITY_FEE', 'LIQUIDITY_BUY_SELL_FEE'];

  public constructor(
    private readonly _translateService: TranslateService,
    private readonly _storeService: StoreService,
  ) {}

  protected setType(type: Type) {
    this._storeService.setType(type);
  }

  protected getLabel(type: Type) {
    return this._translateService.instant(`forms.contract_type.${type.toLowerCase()}.label`);
  }

  protected getSelectedLabel() {
    return this._translateService.instant(`forms.contract_type.${this.lowerCasedType()}.label`);
  }

  protected getSelectedDescription() {
    return this._translateService.instant(`forms.contract_type.${this.lowerCasedType()}.description`);
  }
}

import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faRobot, faSliders } from '@fortawesome/pro-light-svg-icons';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';

import { TokenInfoComponent } from '../shared/token-info/token-info.component';
import { ContractTypeComponent } from '../contract-type/contract-type.component';
import { StoreService } from '../../services/store.service';
import { Type } from '../../models/type.model';
import { StandardTokenOptionsComponent } from './standard-token-options/standard-token-options.component';
import { BurnTokenOptionsComponent } from './burn-token-options/burn-token-options.component';
import { BabyTokenOptionsComponent } from './baby-token-options/baby-token-options.component';
import { LiquidityGeneratorTokenOptionsComponent } from './liquidity-generator-token-options/liquidity-generator-token-options.component';
import { LiquidityFeeTokenOptionsComponent } from './liquidity-fee-token-options/liquidity-fee-token-options.component';
import { LiquidityBuySellFeeTokenOptionsComponent } from './liquidity-buy-sell-fee-token-options/liquidity-buy-sell-fee-token-options.component';
import { DeviceService } from '@dextools/ui-utils';

@Component({
  selector: 'app-advanced-options-form',
  standalone: true,
  imports: [
    BabyTokenOptionsComponent,
    BurnTokenOptionsComponent,
    ContractTypeComponent,
    DividerModule,
    FontAwesomeModule,
    FormsModule,
    InputSwitchModule,
    InputTextModule,
    LiquidityBuySellFeeTokenOptionsComponent,
    LiquidityFeeTokenOptionsComponent,
    LiquidityGeneratorTokenOptionsComponent,
    PanelModule,
    ReactiveFormsModule,
    StandardTokenOptionsComponent,
    TokenInfoComponent,
    TranslateModule,
  ],
  templateUrl: './advanced-options.component.html',
  styleUrls: ['./advanced-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedOptionsComponent {
  protected readonly Type = Type;
  protected readonly icons = {
    faRobot,
    faSliders,
  };

  protected readonly deviceName = this._deviceService.getDeviceConfig('displayResolution');
  protected token = this._storeService.token();
  protected type = this._storeService.type;

  protected form = new FormGroup({
    hasAntibot: new FormControl<boolean>(this.token.hasAntibot, { nonNullable: true }),
  });

  public constructor(
    private readonly _deviceService: DeviceService,
    private readonly _destroyRef: DestroyRef = inject(DestroyRef),
    private readonly _storeService: StoreService,
  ) {
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      this._storeService.updateToken(value);
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';

import { AdvancedFormComponent } from '../advanced-form/advanced-form.component';
import { BasicFormComponent } from '../basic-form/basic-form.component';
import { ContractModeComponent } from '../mode/mode.component';
import { TokenInfoComponent } from '../shared/token-info/token-info.component';
import { StepsComponent } from '../steps/steps.component';
import { Mode } from '../../models/mode.model';
import { FormService } from '../../services/form.service';
import { StoreService } from '../../services/store.service';
import { TokenCreatorService } from '../../services/token-creator.service';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    AdvancedFormComponent,
    BasicFormComponent,
    ButtonModule,
    ContractModeComponent,
    FormsModule,
    MessagesModule,
    ReactiveFormsModule,
    StepsComponent,
    TokenInfoComponent,
    TranslateModule,
  ],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent {
  protected readonly Mode = Mode;
  protected readonly mode = this._storeService.mode;

  protected readonly blockchain = toSignal(this._tokenCreatorService.blockchain$);

  public constructor(
    private readonly _formService: FormService,
    private readonly _storeService: StoreService,
    private readonly _tokenCreatorService: TokenCreatorService,
  ) {}

  protected async submit() {
    this._formService.submit();
  }
}
